<template>
  <div>
    <FilterTray
      title="Filter Underwriting"
      :filters-active="isActive"
      @clear-filters="clearFilters"
    >
      <FilterSection label="Underwriting Status" collapsible>
        <b-field v-for="status in underwriting_status" :key="status.value">
          <b-checkbox v-model="filters.underwriting_status" :native-value="status.value"> {{ status.name }} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="KYC Status" collapsible>
        <b-field v-for="status in kyc_status" :key="status.value">
          <b-checkbox v-model="filters.kyc_status" :native-value="status.value"> {{ status.name }} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Due Date" collapsible>
        <b-field>
          <b-select v-model="filters.dueDate">
            <option v-for="date in dueDate" :key="date.value" :value="date.value">{{ date.name }}</option>
          </b-select>
        </b-field>
      </FilterSection>
      <FilterSection label="Target Date" collapsible>
        <b-field>
          <b-select v-model="filters.targetDate">
            <option v-for="date in targetDate" :key="date.value" :value="date.value">{{ date.name }}</option>
          </b-select>
        </b-field>
      </FilterSection>
      <FilterSection label="Onboarding Type" collapsible>
        <b-field v-for="t in onboardingType" :key="t.value">
          <b-checkbox v-model="filters.onboarding_type" :native-value="t.value"> {{ t.name }} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Advanced" collapsible>
        <b-field>
          <b-switch v-model="filters.advancedToggle" :native-value="false">View Archived Plans</b-switch>
        </b-field>
      </FilterSection>
    </FilterTray>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: { FilterTray, FilterSection },
  data() {
    return {
      filters: cloneDeep(this.$store.getters.filtersFor('underwritingPlan')),
    };
  },
  computed: {
    isActive() {
      return !isEmpty(this.$store.getters.activeFiltersFor('underwritingPlan'));
    },
    underwriting_status() {
      return this.$store.getters.underwritingStatus;
    },
    kyc_status() {
      return this.$store.getters.kycStatus;
    },
    dueDate() {
      return this.$store.getters.dueDate;
    },
    targetDate() {
      return this.$store.getters.targetDate;
    },
    onboardingType() {
      return this.$store.getters.onboardingType;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'underwritingPlan' }).then(() => {
        this.filters = cloneDeep(this.$store.getters.filtersFor('underwritingPlan'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'underwritingPlan', filters: cloneDeep(this.filters) });
    },
  },
};
</script>

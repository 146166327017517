<template>
  <spinner v-if="loading"/>
  <div v-else>
    <PageNavigation page-title="Contracts"/>
    <TablePaginated
      name="Contracts"
      :filters-active="hasFiltersActive"
      :data="computedData"
      searchable
      @queryUpdated="searchQuery = $event"
      class="contracts-table"
    >
      <template slot="header-left">
        <div class="block" v-if="!pastDue.length">
          <div class="block-left">
            <p>View Clubs Renewing by:</p>
            <DateRangeDropdown
              v-model="dateRange"
                :presets="[
                 { name: 'Next 30 Days', display: 'Next 30 Days', range: next30Days },
                 { name: 'Next 3 Months', display: 'Next 3 Months', range: next3Months },
                 { name: 'Next Year', display: 'Next Year', range: nextYear },
                ]"
              @input="getDate($event.startDate, $event.endDate)" />
          </div>
        </div>
        <b-tabs
          v-else
          v-model="activeTab"
          type="is-toggle"
          class="b-tabs-no-content">
          <b-tab-item label="Past Due"/>
          <b-tab-item label="By Month"/>
          <b-tab-item label="All"/>
        </b-tabs>
      </template>
      <template slot="table-header">
        <div class="block">
          <div class="block-left" v-if="activeTab === 2 && pastDue.length">
            <p>View Clubs Renewing by:</p>
            <DateRangeDropdown
              v-model="dateRange"
                :presets="[
                 { name: 'Next 30 Days', display: 'Next 30 Days', range: next30Days },
                 { name: 'Next 3 Months', display: 'Next 3 Months', range: next3Months },
                 { name: 'Next Year', display: 'Next Year', range: nextYear },
                 { name: 'Lifetime', display: 'Lifetime', range: lifetime },
                ]"
              @input="getDate($event.startDate, $event.endDate)" />
          </div> <br>
          <div class="club-found-message" v-if="activeTab === 2 || !pastDue.length"> <strong>
            <span v-if=" filteredContracts.length === 1"> {{ filteredContracts.length }} Club Contract Found</span>
            <span v-else> {{ filteredContracts.length }} Club Contracts Found</span>
          </strong> </div>
          <div class="club-found-message" v-if="activeTab === 0 && pastDue.length"> <strong>
            <span v-if=" searchedPastDue.length === 1"> {{ searchedPastDue.length }} Club Contract Found</span>
            <span v-else> {{ searchedPastDue.length }} Club Contracts Found</span>
          </strong> </div>
        </div>
        <div v-if="activeTab === 1" class="club-found-message">
          <ViewSelector
            v-model="monthToView"
            :options="months"/>
        </div>
      </template>
      <template slot-scope="props">
        <b-table-column
          header-class="is-unselectable"
          width="2rem"
          field="alert">
          <CustomerHealthModalPopover v-if="props.row.health_status" :customerHealth="props.row"/>
        </b-table-column>
        <b-table-column label="Club Name"
          class="align-bottom"
          field="club_name"
          header-class="is-unselectable"
          sortable>
          {{ props.row.club_name }}
        </b-table-column>
        <b-table-column label="Club Size"
          field="size"
          header-class="is-unselectable"
          sortable>
          {{ props.row.size | capitalize }}
        </b-table-column>
        <b-table-column label="Sport"
          field="sport"
          header-class="is-unselectable"
          sortable>
          {{ props.row.sport | capitalize }}
        </b-table-column>
        <b-table-column label="Renewal Date"
          field="renew_date"
          header-class="is-unselectable"
          sortable
          :custom-sort="(contract1, contract2, isAsc) => sortDates(contract1, contract2, isAsc)">
          {{ props.row.renew_date | formatDate('ll z') }}
        </b-table-column>
        <b-table-column width="is-narrow"
          label="Rec Player Count"
          numeric
          field="rec_player_median"
          header-class="is-unselectable"
          sortable>
          <div class='contracts__player-actual'> <p class="delta">
              {{ props.row.rec_player_median.toLocaleString() }}
          </p> </div>
        </b-table-column>
        <b-table-column width="60"
          label="Rec Player Cap"
          numeric
          field="rec_player_cap"
          sortable
          header-class="is-unselectable">
          {{ props.row.rec_player_cap.toLocaleString() }}
        </b-table-column>
        <b-table-column width="60"
          label="Rec Player Diff"
          numeric
          field="rec_player_delta"
          sortable
          :class="calculatePercentage(props.row.rec_player_delta, props.row.rec_player_cap)"
          header-class="is-unselectable">
          {{ props.row.rec_player_delta.toLocaleString() }}
        </b-table-column>
        <b-table-column width="is-narrow"
          label="Comp Player Count"
          numeric
          field="competitive_player_median"
          header-class="is-unselectable"
          sortable>
          <div class='contracts__player-actual'> <p class="delta">
            {{ props.row.competitive_player_median.toLocaleString() }}
          </p> </div>
        </b-table-column>
        <b-table-column width="60"
          label="Comp Player Cap"
          numeric
          field="competitive_player_cap"
          header-class="is-unselectable"
          sortable>
          {{ props.row.competitive_player_cap.toLocaleString() }}
        </b-table-column>
        <b-table-column width="60"
          label="Comp Player Diff"
          numeric
          field="competitive_player_delta"
          sortable
          :class="calculatePercentage(props.row.competitive_player_delta, props.row.competitive_player_cap)"
                        header-class="is-unselectable">
          {{ props.row.competitive_player_delta.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Sales Rep"
          field="sales_contact_user_name"
          header-class="is-unselectable"
          sortable>
          {{ props.row.sales_contact_user_name }}
        </b-table-column>
        <b-table-column centered
          label="Actions"
          field="actions"
          header-class="is-unselectable">
          <div class='contracts__action-container'>
            <b-button class="button is-text"
              :disabled="props.row.isArchived!==null"
              @click="rosterCount(props.row)">
              <fa-icon :icon="['fas', 'chart-bar']" />
            </b-button>
            <b-dropdown
              ref="dropdown"
              aria-role="list"
              position="is-bottom-left"
              :disabled="props.row.isArchived!==null">
              <a
                slot="trigger"
                class="button is-text">
                <fa-icon :icon="['far', 'ellipsis-h']" />
              </a>
              <b-dropdown-item
                aria-role="listitem"
                @click="exportCounts(props.row)">
                <div class="columns is-mobile">
                  <div class="column is-narrow" style="width:20px">
                    <fa-icon :icon="['fas', 'chart-bar']" />
                  </div>
                  <div class="column">
                    Export Counts
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                @click="renewContract(props.row)">
                <div class="columns is-mobile">
                  <div class="column is-narrow" style="width:20px">
                    <fa-icon :icon="['fas', 'pencil']" />
                  </div>
                  <div class="column">
                    Renew Contract
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                @click="editContract(props.row)">
                <div class="columns is-mobile">
                  <div class="column is-narrow" style="width:20px">
                    <fa-icon :icon="['fas', 'pencil']" />
                  </div>
                  <div class="column">
                    Edit Contract
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                @click="closeContract(props.row)">
                <div class="columns is-mobile has-text-danger">
                  <div class="column is-narrow" style="width:20px">
                    <fa-icon :icon="['far', 'calendar-times']" />
                  </div>
                  <div class="column">
                    Close Contract
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Contracts Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <ContractsFilter :filters-active="hasFiltersActive"/>
    <RosterCountsModal @dismissed="rosterCountsDismissed" ref="rosterCounts"/>
    <RenewContractModal @dismissed="renewContractDismissed" ref="renewContracts"/>
    <EditContractModal @dismissed="editContractDismissed" ref="editContracts"/>
  </div>
</template>

<script>
import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash';
import Spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import TablePaginated from '@/components/layout/TablePaginated';
import DateRangeDropdown from '@/components/common/DateRangeDropdown';
import CustomerHealthModalPopover from '@/components/common/CustomerHealthModalPopover';
import RosterCountsModal from '@/components/contracts/RosterCountsModal';
import RenewContractModal from '@/components/contracts/RenewContractModal';
import EditContractModal from '@/components/contracts/EditContractModal';
import ViewSelector from '@/components/common/ViewSelector';
import ContractsFilter from './ContractsFilter';

const defaultDateRange = {
  startDate: moment().toDate(),
  endDate: moment().add(30, 'days').toDate(),
};

export default {
  components: { Spinner,
    TablePaginated,
    PageNavigation,
    DateRangeDropdown,
    RosterCountsModal,
    RenewContractModal,
    EditContractModal,
    ContractsFilter,
    ViewSelector,
    CustomerHealthModalPopover },

  data() {
    return {
      monthToView: moment().format('MMMM'),
      activeTab: 0,
      searchQuery: '',
      loading: false,
      contracts: [],
      clubInternals: [],
      recMedian: [],
      compMedian: [],
      contractIn: { name: '' },
      dateRange: cloneDeep(defaultDateRange),
      clubs: [],
    };
  },
  computed: {
    computedData() {
      if (this.pastDue.length && this.activeTab === 0) {
        return this.searchedPastDue;
      } else if (this.activeTab === 1) {
        return this.byMonthContracts;
      } else {
        return this.filteredContracts;
      }
    },
    months() {
      return moment.months();
    },
    users() {
      return this.$store.getters.users;
    },
    salesUsers() {
      return this.$store.getters.salesUsers;
    },
    clubsByID() {
      return this.clubs.reduce((acc, club) => ({ ...acc, [club.id]: club }), {});
    },
    clubInternalsByID() {
      return this.clubInternals.reduce((acc, club) => ({ ...acc, [club.id]: club }), {});
    },
    completedContracts() {
      return this.contracts.filter(contract => contract.renew_date).map(contract => ({ ...contract,
        club_name: this.clubsByID[contract.club_id].name,
        club_size: this.clubsByID[contract.club_id].size,
        club_sport: this.clubSports(this.clubsByID[contract.club_id].sports),
        sales_contact_user_id: this.clubsByID[contract.club_id].config.sales_contact_user_id || 0,
        sales_contact_user_name: this.clubSalesContactName(this.clubsByID[contract.club_id].config.sales_contact_user_id),
        rec_player_median: this.recMedian[contract.club_id],
        competitive_player_median: this.compMedian[contract.club_id],
        is_test_club: this.clubsByID[contract.club_id].is_test_club,
        is_deleted: this.clubsByID[contract.club_id].is_deleted,
      }));
    },
    tableRows() {
      return this.completedContracts.map(contract => ({
        id: contract.id,
        club_id: contract.club_id,
        club_name: contract.club_name ? contract.club_name : '-',
        size: contract.club_size ? contract.club_size : '-',
        health_status: this.clubInternalsByID[contract.club_id]?.health_status || '',
        health_notes: this.clubInternalsByID[contract.club_id]?.health_notes || '',
        health_updated_at: this.clubInternalsByID[contract.club_id]?.health_updated_at || '',
        health_updated_by_user_id: this.clubInternalsByID[contract.club_id]?.health_updated_by_user_id || 0,
        renew_date: contract.renew_date,
        sign_date: contract.sign_date,
        sport: contract.club_sport,
        rec_player_cap: contract.rec_player_cap ? contract.rec_player_cap : '-',
        rec_player_median: contract.rec_player_median ? contract.rec_player_median : 0,
        rec_player_delta: this.getDelta(contract.rec_player_cap, contract.rec_player_median || 0),
        competitive_player_cap: contract.competitive_player_cap ? contract.competitive_player_cap : '-',
        competitive_player_median: contract.competitive_player_median ? contract.competitive_player_median : 0,
        competitive_player_delta: this.getDelta(contract.competitive_player_cap, contract.competitive_player_median || 0),
        sales_contact_user_name: contract.sales_contact_user_name ? contract.sales_contact_user_name : '-',
        sales_contact_user_id: contract.sales_contact_user_id,
        isArchived: contract.archived_at,
        is_test_club: contract.is_test_club,
        is_deleted: contract.is_deleted,
      }));
    },
    archivedContracts() {
      if (this.$store.getters.activeFiltersFor('contract').isArchived) {
        return this.tableRows.filter(contract => contract.isArchived);
      } else {
        return this.tableRows.filter(contract => !contract.isArchived);
      }
    },
    byMonthContracts() {
      return this.searchedContracts.filter((contract) => {
        const contractMonth = moment(contract.renew_date).format('MMMM');
        return contractMonth === this.monthToView;
      });
    },
    searchedContracts() {
      return this.archivedContracts.filter(contract => this.matchesName(contract.club_name, this.searchQuery)
        && this.matchesContractFilter(contract) && !(contract.is_test_club || contract.is_deleted)).sort((contract1, contract2) =>
        ((contract1.renew_date < contract2.renew_date) ? -1 : 1));
    },
    filteredContracts() {
      return this.searchedContracts.filter(searchedContract =>
        this.filterDate(new Date(searchedContract.renew_date),
          new Date(this.dateRange.startDate), new Date(this.dateRange.endDate)));
    },
    pastDue() {
      return this.archivedContracts.filter(contract =>
        (new Date(contract.renew_date) <= new Date()));
    },
    searchedPastDue() {
      return this.searchedContracts.filter(searchedContracts =>
        (new Date(searchedContracts.renew_date) <= new Date()));
    },
    next30Days() {
      return {
        startDate: moment().toDate(),
        endDate: moment().add(30, 'days').toDate(),
      };
    },
    next3Months() {
      return {
        startDate: moment().toDate(),
        endDate: moment().add(3, 'months').toDate(),
      };
    },
    nextYear() {
      return {
        startDate: moment().toDate(),
        endDate: moment().add(1, 'years').toDate(),
      };
    },
    lifetime() {
      return {
        startDate: new Date('01-10-2018'),
        endDate: new Date('01-01-2100'),
      };
    },
    hasFiltersActive() {
      return !isEmpty(this.$store.getters.activeFiltersFor('contract'));
    },
  },
  created() {
    this.fetchMedianCounts();
    this.fetchContracts();
  },
  methods: {
    loadClubs() {
      this.loading = true;
      this.clubs = this.$store.getters.clubs;
      this.loading = false;
    },
    adminUsersByID(id) {
      return this.users.find(user => user.id === id);
    },
    clubSalesContactName(id) {
      if (!id) return '-';
      return this.salesUsers[id] || '-';
    },
    clubSports(sports) {
      if (sports.length === 0) {
        return '-';
      } return sports[0].sport;
    },
    sortDates(contract1, contract2, isAsc) {
      const date1 = new Date(contract1.renew_date);
      const date2 = new Date(contract2.renew_date);
      return (date1 < date2) === isAsc ? -1 : 1;
    },
    // player delta's
    getDelta(cap, median) {
      if (cap) return cap - median;
      if (median) return -1 * median;
      else return 0;
    },
    calculatePercentage(delta, cap) {
      if (cap === '-' && delta) return 'has-text-danger';
      if (delta / cap < -0.1) return 'has-text-danger';
      if (delta / cap <= 0) return 'has-text-warning-dark';
      else return '';
    },
    deltaToString(delta) {
      return `${delta}`;
    },
    // fetch data
    fetchContracts() {
      this.loading = true;
      Promise.all([
        api().post('/cst/club_contracts/search').then((res) => {
          this.contracts = res.data;
        }),
        api().get('/cst/club_internals/').then((res) => {
          this.clubInternals = res.data;
        }),
        this.$store.dispatch('searchClubs').then(() => this.loadClubs()),
      ]).finally(() => {
        this.loading = false;
      });
    },
    fetchMedianCounts() {
      api().post('/cst/usage/median', 10002).then((res) => {
        this.recMedian = res.data;
      });
      api().post('/cst/usage/median', 10003).then((res) => {
        this.compMedian = res.data;
      });
    },
    // roster counts modal
    rosterCount(contract) {
      this.$refs.rosterCounts.showModal(contract);
    },
    rosterCountsDismissed(option) {
      if (option.refreshData) {
        this.fetchContracts();
      }
    },
    // close/archive contract modal
    closeContract(contract) {
      this.$confirm.showModal({
        confirmText: 'Yes, Close Contract',
        message: `You are about to close <strong>${contract.club_name}'s</strong> contract.
                  Once the contract is closed you will no longer be able to access it.
                  <br><br>
                  Are you sure you want to continue?`,
        title: 'Close Contract',
        type: 'is-danger',
        icon: 'archive',
        onConfirm: () => {
          api().post(`cst/club_contracts/${contract.id}/archive`, contract).then(() => {
            this.fetchContracts();
            this.$store.commit('setNotification', { toast: true, success: true, message: 'Contract Closed' });
          });
        },
      });
    },
    // CSV export modal
    exportCounts(contract) {
      api().serverURL(`/cst/clubs/${contract.club_id}/roster_counts.csv`).then((url) => {
        window.open(url, '_blank');
      });
    },
    // renew contract modal
    renewContract(contract) {
      this.$refs.renewContracts.showModal(contract);
    },
    renewContractDismissed(option) {
      if (option.refreshData) {
        this.fetchContracts();
      }
    },
    // edit contract modal
    editContract(contract) {
      this.$refs.editContracts.showModal(contract);
    },
    editContractDismissed(option) {
      if (option.refreshData) {
        this.fetchContracts();
      }
    },
    getDate(start, end) {
      this.transactions = null;
      const searchBody = {};
      if (start) {
        searchBody.start_date = moment(start).format('YYYY-MM-DD');
      }
      if (end) {
        searchBody.end_date = moment(end).format('YYYY-MM-DD');
      }
    },
    filterDate(renewal, start, end) {
      return (renewal >= start && renewal <= end);
    },
    matchesName(name, searchQuery) {
      return name === '-' ? false : name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    matchesContractFilter(contract) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('contract');
      return Object.keys(filters).filter(key => typeof filters[key] !== 'boolean').every(key => filters[key].includes(contract[key])
        || (filters[key].includes(this.calculatePercentage(this.getDelta(contract.rec_player_cap, contract.rec_player_median), contract, 'rec')) && key === 'recCap')
        || (filters[key].includes(this.calculatePercentage(this.getDelta(contract.competitive_player_cap, contract.competitive_player_median), contract, 'comp')) && key === 'compCap'));
    },

  },
};
</script>

<style lang="sass" scoped>
.contracts__action-container
  display: inline-flex
.contracts__player-actual
  display: inline-flex-span
  .delta
    display: inline-flex
    font-size: 14px
.club-found-message
  padding-top: 1rem
</style>
<style lang="sass">
.contracts-table .table th
  vertical-align: bottom
</style>

import _ from 'lodash';
import moment from 'moment';
import store from '@/store/store';

const isMobile = () => {
  const vendor = navigator.userAgent || navigator.vendor || window.opera;

  return !!(
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playmetricsios|playbook|silk/i.test(  //eslint-disable-line
      vendor) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test( //eslint-disable-line
      vendor.substr(0, 4))
  );
};

const TBD_DATE = moment('3000-12-31 00:00:00', 'YYYY-MM-DD hh:mm:ss').toDate();

const isTbdDate = (date) => {
  const tbdDate = moment(TBD_DATE).subtract(2, 'day');
  return moment(date).isSameOrAfter(tbdDate);
};
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  //eslint-disable-line
const websiteRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/; //eslint-disable-line
const isValidWebsiteUrl = url => websiteRegex.test(url);
const isValidEmailFormat = email => emailRegex.test(email);
const normalizeString = s => (typeof s === 'string' ? s.trim().toLowerCase() : s);

const omitDeep = (input, excludes) =>
  Object.entries(input).reduce((nextInput, [key, value]) => {
    const shouldExclude = excludes.includes(key);
    if (shouldExclude) return nextInput;

    if (Array.isArray(value)) {
      nextInput[key] = value.map((arrItem) => {
        if (typeof arrItem === 'object') {
          return omitDeep(arrItem, excludes);
        }
        return arrItem;
      });
      return nextInput;
    } else if (value !== null && typeof value === 'object') {
      nextInput[key] = omitDeep(value, excludes);
      return nextInput;
    }

    nextInput[key] = value;
    return nextInput;
  }, {});

const TIMEZONES = [
  // US
  // { country: 'US', id: 'America/Adak' },
  { country: 'US', id: 'America/Anchorage' },
  // { country: 'US', id: 'America/Boise' },
  { country: 'US', id: 'America/Chicago' },
  { country: 'US', id: 'America/Denver' },
  { country: 'US', id: 'America/Detroit' },
  { country: 'US', id: 'America/Indiana/Indianapolis' },
  // { country: 'US', id: 'America/Indiana/Knox' },
  // { country: 'US', id: 'America/Indiana/Marengo' },
  // { country: 'US', id: 'America/Indiana/Petersburg' },
  // { country: 'US', id: 'America/Indiana/Tell_City' },
  // { country: 'US', id: 'America/Indiana/Vevay' },
  // { country: 'US', id: 'America/Indiana/Vincennes' },
  // { country: 'US', id: 'America/Indiana/Winamac' },
  // { country: 'US', id: 'America/Juneau' },
  // { country: 'US', id: 'America/Kentucky/Louisville' },
  // { country: 'US', id: 'America/Kentucky/Monticello' },
  { country: 'US', id: 'America/Los_Angeles' },
  // { country: 'US', id: 'America/Menominee' },
  // { country: 'US', id: 'America/Metlakatla' },
  { country: 'US', id: 'America/New_York' },
  // { country: 'US', id: 'America/Nome' },
  // { country: 'US', id: 'America/North_Dakota/Beulah' },
  // { country: 'US', id: 'America/North_Dakota/Center' },
  // { country: 'US', id: 'America/North_Dakota/New_Salem' },
  { country: 'US', id: 'America/Phoenix' },
  // { country: 'US', id: 'America/Shiprock' },
  // { country: 'US', id: 'America/Sitka' },
  { country: 'US', id: 'Pacific/Honolulu' },
  // Canada
  // { country: 'CA', id: 'America/Atikokan' },
  // { country: 'CA', id: 'America/Blanc-Sablon' },
  // { country: 'CA', id: 'America/Cambridge_Bay' },
  // { country: 'CA', id: 'America/Creston' },
  // { country: 'CA', id: 'America/Dawson' },
  // { country: 'CA', id: 'America/Dawson_Creek' },
  { country: 'CA', id: 'America/Edmonton' },
  // { country: 'CA', id: 'America/Glace_Bay' },
  // { country: 'CA', id: 'America/Goose_Bay' },
  // { country: 'CA', id: 'America/Halifax' },
  // { country: 'CA', id: 'America/Inuvik' },
  // { country: 'CA', id: 'America/Iqaluit' },
  // { country: 'CA', id: 'America/Moncton' },
  { country: 'CA', id: 'America/Montreal' },
  // { country: 'CA', id: 'America/Nipigon' },
  // { country: 'CA', id: 'America/Pangnirtung' },
  // { country: 'CA', id: 'America/Rainy_River' },
  // { country: 'CA', id: 'America/Rankin_Inlet' },
  { country: 'CA', id: 'America/Regina' },
  // { country: 'CA', id: 'America/St_Johns' },
  // { country: 'CA', id: 'America/Swift_Current' },
  // { country: 'CA', id: 'America/Thunder_Bay' },
  { country: 'CA', id: 'America/Toronto' },
  { country: 'CA', id: 'America/Vancouver' },
  // { country: 'CA', id: 'America/Whitehorse' },
  { country: 'CA', id: 'America/Winnipeg' },
  // { country: 'CA', id: 'America/Yellowknife' },
];

const STATES = [
  { country: 'US', abbr: 'AL', name: 'Alabama' },
  { country: 'US', abbr: 'AK', name: 'Alaska' },
  { country: 'US', abbr: 'AZ', name: 'Arizona' },
  { country: 'US', abbr: 'AR', name: 'Arkansas' },
  { country: 'US', abbr: 'CA', name: 'California' },
  { country: 'US', abbr: 'CO', name: 'Colorado' },
  { country: 'US', abbr: 'CT', name: 'Connecticut' },
  { country: 'US', abbr: 'DC', name: 'District of Columbia' },
  { country: 'US', abbr: 'DE', name: 'Delaware' },
  { country: 'US', abbr: 'FL', name: 'Florida' },
  { country: 'US', abbr: 'GA', name: 'Georgia' },
  { country: 'US', abbr: 'HI', name: 'Hawaii' },
  { country: 'US', abbr: 'ID', name: 'Idaho' },
  { country: 'US', abbr: 'IL', name: 'Illinois' },
  { country: 'US', abbr: 'IN', name: 'Indiana' },
  { country: 'US', abbr: 'IA', name: 'Iowa' },
  { country: 'US', abbr: 'KS', name: 'Kansas' },
  { country: 'US', abbr: 'KY', name: 'Kentucky' },
  { country: 'US', abbr: 'LA', name: 'Louisiana' },
  { country: 'US', abbr: 'ME', name: 'Maine' },
  { country: 'US', abbr: 'MD', name: 'Maryland' },
  { country: 'US', abbr: 'MA', name: 'Massachusetts' },
  { country: 'US', abbr: 'MI', name: 'Michigan' },
  { country: 'US', abbr: 'MN', name: 'Minnesota' },
  { country: 'US', abbr: 'MS', name: 'Mississippi' },
  { country: 'US', abbr: 'MO', name: 'Missouri' },
  { country: 'US', abbr: 'MT', name: 'Montana' },
  { country: 'US', abbr: 'NE', name: 'Nebraska' },
  { country: 'US', abbr: 'NV', name: 'Nevada' },
  { country: 'US', abbr: 'NH', name: 'New Hampshire' },
  { country: 'US', abbr: 'NJ', name: 'New Jersey' },
  { country: 'US', abbr: 'NM', name: 'New Mexico' },
  { country: 'US', abbr: 'NY', name: 'New York' },
  { country: 'US', abbr: 'NC', name: 'North Carolina' },
  { country: 'US', abbr: 'ND', name: 'North Dakota' },
  { country: 'US', abbr: 'OH', name: 'Ohio' },
  { country: 'US', abbr: 'OK', name: 'Oklahoma' },
  { country: 'US', abbr: 'OR', name: 'Oregon' },
  { country: 'US', abbr: 'PA', name: 'Pennsylvania' },
  { country: 'US', abbr: 'RI', name: 'Rhode Island' },
  { country: 'US', abbr: 'SC', name: 'South Carolina' },
  { country: 'US', abbr: 'SD', name: 'South Dakota' },
  { country: 'US', abbr: 'TN', name: 'Tennessee' },
  { country: 'US', abbr: 'TX', name: 'Texas' },
  { country: 'US', abbr: 'UT', name: 'Utah' },
  { country: 'US', abbr: 'VT', name: 'Vermont' },
  { country: 'US', abbr: 'VA', name: 'Virginia' },
  { country: 'US', abbr: 'WA', name: 'Washington' },
  { country: 'US', abbr: 'WV', name: 'West Virginia' },
  { country: 'US', abbr: 'WI', name: 'Wisconsin' },
  { country: 'US', abbr: 'WY', name: 'Wyoming' },

  { country: 'CA', abbr: 'AB', name: 'Alberta' },
  { country: 'CA', abbr: 'BC', name: 'British Columbia' },
  { country: 'CA', abbr: 'MB', name: 'Manitoba' },
  { country: 'CA', abbr: 'NB', name: 'New Brunswick' },
  { country: 'CA', abbr: 'NL', name: 'Newfoundland and Labrador' },
  { country: 'CA', abbr: 'NT', name: 'Northwest Territories' },
  { country: 'CA', abbr: 'NS', name: 'Nova Scotia' },
  { country: 'CA', abbr: 'NU', name: 'Nunavut' },
  { country: 'CA', abbr: 'ON', name: 'Ontario' },
  { country: 'CA', abbr: 'PE', name: 'Prince Edward Island' },
  { country: 'CA', abbr: 'QC', name: 'Quebec' },
  { country: 'CA', abbr: 'SK', name: 'Saskatchewan' },
  { country: 'CA', abbr: 'YT', name: 'Yukon Territory' },
];

const dateToTimeWithClubOffset = (date, direction) => {
  const localOffset = date.getTimezoneOffset();
  const clubOffset = store ? moment.tz(date, store.getters.clubDetails.timezone).utcOffset() : 0;
  const offset = (localOffset + clubOffset) * 60000;
  if (direction > 0) {
    return date.getTime() + offset;
  }
  return date.getTime() - offset;
};

const zeroPad = number => `${number < 10 ? '0' : ''}${number}`;

const abbreviateCardType = card => card
  .replace('Visa', 'VISA')
  .replace('Mastercard', 'MC')
  .replace('MasterCard', 'MC')
  .replace('Discover', 'DISC')
  .replace('American Express', 'AMEX')
  .replace('AmericanExpress', 'AMEX')
  .replace('Bank Account', 'BANK');

const cardBrand = (card) => {
  const brand = abbreviateCardType(card.description).split(' ')[0];
  return brand;
};

const cardNum = (card) => {
  const num = abbreviateCardType(card.description).split(' ')[1];
  return num ? num.substr(num.length - 4) : '';
};

const isCardExpired = (card) => {
  if (!card.description) return false;
  const parts = abbreviateCardType(card.description).split(' ');
  if (parts[0] === 'BANK') return false;
  const expirationDate = parts[parts.length - 1];
  const expiresAt = moment(expirationDate, 'MM/YYYY').endOf('month');
  return moment(expiresAt).isBefore(moment().tz(store.getters.clubDetails.timezone), 'day');
};

const isCardInvalidStatus = (card) => {
  if (card && card.status) {
    return (card.status === 'unverified' || card.status === 'verification_failed');
  } else {
    return false;
  }
};

export default {
  dateFromString(str) {
    if ((str == null) || (str === '')) {
      return null;
    }
    return moment(str).toDate();
  },
  dateAndTimeToDate(dt, time) {
    const dtStr = moment(dt).format('YYYY-MM-DD');
    const m = moment(`${dtStr} ${time}`);
    if (!m.isValid()) return null;
    return m.toDate();
  },
  dateToString(dt) {
    if (dt == null) return '';
    return moment(dt).format('YYYY-MM-DD');
  },
  dateToStringOrNull(dt) {
    if (dt == null) return null;
    return moment(dt).format('YYYY-MM-DD');
  },
  dateToTime(dt, defaultTime) {
    if (dt == null) return defaultTime;
    return moment(dt).format('HH:mm');
  },
  zeroPad,
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const num = parseFloat((bytes / (k ** i)).toFixed(dm));
    return `${num} ${sizes[i]}`;
  },
  formatDate(date, format = 'll z', timezone) {
    if (_.isNull(date) || isTbdDate(date)) {
      return null;
    }
    let tz = timezone;
    if ((tz == null) || (tz === '')) {
      tz = store.getters.clubDetails.timezone;
    }
    if (tz === '') {
      tz = 'America/New_York';
    }
    let m;
    if (Number.isInteger(date) && date < 5000000000) {
      m = moment.unix(date).tz(tz);
    } else {
      m = moment.tz(date, tz);
    }

    if (format.includes('TODAY')) {
      // Handle today
      let dt = m.format('MMM D');
      if (m.isSame(moment().tz(tz), 'day')) dt = 'Today';
      const rest = format.replace('TODAY', '');
      if (rest === '') return dt;
      const time = m.format(rest);
      return `${dt}${time}`;
    }
    return m.format(format);
  },
  timezoneAbbrForDate(date, timezoneId) {
    const m = moment(date);
    if (timezoneId && timezoneId === 'America/Phoenix') {
      const abbr = m.tz('America/Los_Angeles').format('z') === 'PDT' ? 'PDT' : 'MST';
      return abbr.length === 3 ? `${abbr.charAt(0)}${abbr.charAt(2)}` : abbr;
    } else if (timezoneId) {
      const abbr = m.tz(timezoneId).format('z');
      if (abbr === 'EET' || abbr === 'CET') return abbr;
      return abbr.length === 3 ? `${abbr.charAt(0)}${abbr.charAt(2)}` : abbr;
    } else {
      const abbr = m.tz(store.getters.clubDetails.timezone).format('z');
      if (abbr === 'EET' || abbr === 'CET') return abbr;
      return abbr.length === 3 ? `${abbr.charAt(0)}${abbr.charAt(2)}` : abbr;
    }
  },
  showEventTimezoneAlert(eventDate, eventTimezoneId) {
    if (eventTimezoneId === store.getters.clubDetails.timezone) {
      return false;
    }
    return this.timezoneAbbrForDate(eventDate, eventTimezoneId) !==
      this.timezoneAbbrForDate(eventDate, store.getters.clubDetails.timezone);
  },
  datestringToDate(datestring, timezone) {
    const tz = timezone || store.getters.clubDetails.timezone;
    if (_.isNull(datestring) || isTbdDate(datestring)) {
      return null;
    }
    return moment.tz(datestring, tz).toDate();
  },
  toMoment(dt, timezone) {
    const tz = timezone || store.getters.clubDetails.timezone;
    if (_.isNull(dt) || isTbdDate(dt)) return null;
    if (Number.isInteger(dt) && dt < 5000000000) return moment.unix(dt).tz(tz);
    return moment.tz(dt, tz);
  },
  isZeroTime(datestring, timezone) {
    return this.formatDate(datestring, 'HH:mm:ss', timezone) === '00:00:00';
  },
  omitDeep,
  isMobile,
  // STATES,
  statesForCountry(country) {
    return _.filter(STATES, state => state.country === country);
  },
  // TIMEZONES,
  timezonesForCountry(country) {
    return _.filter(TIMEZONES, tz => tz.country === country);
  },
  dateToTimeWithClubOffset,
  minDate() {
    const ages = store.getters.clubDetails.config.ages;
    const years = ages[ages.length - 1];
    return moment().subtract(years, 'years').format('YYYY-MM-DD');
  },
  maxDate() {
    const years = store.getters.clubDetails.config.ages[0] - 1;
    return moment().subtract(years, 'years').format('YYYY-MM-DD');
  },
  TBD_DATE,
  isTbdDate,
  formatPhone(phoneNumber) {
    if (_.isEmpty(phoneNumber)) {
      return phoneNumber;
    }
    if (phoneNumber.startsWith('+1')) {
      return phoneNumber.replace(/(\+\d{1})(\d{3})(\d{3})(\d{4})/, '$2-$3-$4');
    }
    // TODO handle 3-digit country codes
    return phoneNumber.replace(/(\d{2})(\d)/, '$1-$2');
  },
  formatAddress(addressObject) {
    const addressParts = [
      addressObject.line1,
      addressObject.line2,
      `${addressObject.city} ${addressObject.region || addressObject.state}`,
      addressObject.postal_code];
    return _.compact(addressParts).join(', ');

    // let address = addressObject.line1;
    // if (addressObject.line2) {
    //   address += `, ${address.line2}`;
    // }
    // address += `, ${addressObject.city} ${addressObject.region || addressObject.state}, ${addressObject.postal_code}`;
    // return address;
  },
  hackPhoneNumberCountryCode(phoneNumber) {
    let phoneNumberHack = phoneNumber;
    if (phoneNumberHack) {
      if ((phoneNumberHack.length >= 8) && !phoneNumberHack.startsWith('+')) {
        // assume US/CA for now
        if (!phoneNumberHack.startsWith('1')) {
          phoneNumberHack = '1'.concat(phoneNumberHack);
        }
        phoneNumberHack = '+'.concat(phoneNumberHack);
      }
      phoneNumberHack = phoneNumberHack.replace(/[^+0-9]+/g, '');
    }
    return phoneNumberHack;
  },
  getColorIdForTeam(teamId) {
    let id = store.getters.teamColorId(teamId);
    if (id === -1) {
      store.commit('addTeamColorId', teamId);
      id = store.getters.teamColorId(teamId);
    }
    return id;
  },
  generateGUID() {
    let d = new Date().getTime();
    const newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // eslint-disable-next-line
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return newGuid;
  },
  cardBrand,
  cardNum,
  cardIcon(card) {
    const brand = abbreviateCardType(card.description).split(' ')[0];
    if (brand === 'AMEX') return ['fab', 'cc-amex'];
    if (brand === 'DISC') return ['fab', 'cc-discover'];
    if (brand === 'MC') return ['fab', 'cc-mastercard'];
    if (brand === 'BANK') return ['far', 'university'];
    return ['fab', 'cc-visa'];
  },
  cardExp(card) {
    const parts = abbreviateCardType(card.description).split(' ');
    if (parts[0] === 'BANK') return '';
    const expiration = parts[parts.length - 1];
    const month = expiration.split('/')[0];
    const year = expiration.split('/')[1];
    return `${zeroPad(month)}/${year.substr(year.length - 2)}`;
  },
  cardDescription(card) {
    const brand = cardBrand(card);
    const num = cardNum(card);
    const re = /^\d{4}$/;
    if (re.test(num)) {
      return `${brand}-${num}`;
    }
    return '';
  },
  abbreviateCardType,
  isCardExpired,
  isCardInvalidStatus,
  passwordErrors(password) {
    const rules = [
      { message: 'Password should be at least 8 characters', regex: /.{8,}/ },
      { message: 'Password requires at least one lowercase letter', regex: /[a-z]+/ },
      { message: 'Password requires at least one uppercase letter', regex: /[A-Z]+/ },
      { message: 'Password requires at least one number', regex: /[0-9]+/ },
      { message: 'Password requires at least one special character', regex: /[^a-zA-Z\d]+/ },
    ];
    const broken = _.filter(rules, rule => !rule.regex.test(password));
    return broken.map(r => r.message);
  },
  pluralize(term) {
    // TODO: Expand for more common cases
    let newTerm = term.toLowerCase();
    const lastChar = newTerm.substring(newTerm.length - 1);
    const lastTwo = newTerm.substring(newTerm.length - 2);
    if (lastChar === 'y') {
      if (lastTwo === 'ay' || lastTwo === 'ey' || lastTwo === 'iy' || lastTwo === 'oy' || lastTwo === 'uy') {
        newTerm = newTerm.concat('s');
      }
      newTerm = newTerm.substring(0, newTerm.length - 1).concat('ies');
    }
    newTerm = newTerm.concat('s');

    if (term[0] === term[0].toUpperCase()) {
      return newTerm[0].toUpperCase() + newTerm.substring(1);
    }
    return newTerm;
  },
  asGenderWord(gender) {
    switch (gender) {
      case 'M': case 'm': return 'Male';
      case 'F': case 'f': return 'Female';
      case 'C': case 'c': return 'Co-Ed';
      case 'All' : return 'All';
      default: return '';
    }
  },
  isValidEmailFormat,
  isValidWebsiteUrl,
  normalizeString,
};

<template>
  <div>
    <Modal
      ref="modal"
      :title="`Import ${system} Club Values`"
      @closeModal="clear()"
      :hasCancelButton="true">
      <template>
        <slot />
        <div>
          <div v-if="file">
            <div v-if="file" class="level">
              <div class="level-left">
                {{ file.name }}
              </div>
              <button
                class="level-right button remove-button"
                @click="removeFile">
                <fa-icon :icon="['far', 'times']"/>
              </button>
            </div>
            <b-notification
              v-if="validating || importing"
              :closable="false"
              class="progress-notification">
              <span class="has-text-primary is-size-4 mr-4">
                <fa-icon :icon="['fas', 'spinner']" class="fa-spin" />
              </span>
              <span v-if="validating">Checking <span class="is-italic">{{ file.name }}</span> for errors...</span>
              <span v-else>Importing <span class="is-italic">{{ file.name }}</span>...</span>
            </b-notification>
            <b-notification v-else-if="hasErrors" :closable="false">
              <div class="media">
                <div class="media-left">
                  <b-icon
                    class="has-text-danger"
                    size="is-medium"
                    icon="exclamation-circle"/>
                </div>
                <div class="media-content">
                  <div>
                    <p class="has-text-weight-bold has-text-danger">Errors found in {{  file.name }}</p>
                    <ul>
                      <li v-for="(error, incre) in errors" :key="incre">
                        {{  error }}
                      </li>
                    </ul>
                  </div>
                  <div
                    v-if="hasMessages"
                    class="mt-4">
                    Details:
                    <ul>
                      <li v-for="(message, idx) in messages" :key="idx">
                        {{ message }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </b-notification>
            <b-notification v-else-if="validated" :closable="false">
              <div class="media">
                <div class="media-left">
                  <b-icon
                    class="has-text-success"
                    size="is-medium"
                    icon="check-circle"/>
                </div>
                <div class="media-content">
                  <div>
                    {{ file.name }} is ready for import
                    <ul>
                      <li v-for="(action, incre) in actions" :key="incre">
                        {{ action }}
                      </li>
                    </ul>
                  </div>
                  <div
                    v-if="hasMessages"
                    class="mt-4">
                    Details:
                    <ul>
                      <li v-for="(message, idx) in messages" :key="idx">
                        {{ message }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </b-notification>
          </div>
          <b-field v-else>
            <b-upload v-model="file"
            accept="csv"
            drag-drop
            expanded
            @input="validateFile($event)">
            <section class="section upload-content">
              <div class="content">
                <span class="ml-4">
                  Drop a .csv file here or
                  <span class="clickable file-select is-underline">browse</span>
                  to upload
                </span>
              </div>
            </section>
          </b-upload>
          </b-field>
          <div>
            <a
              :href="sampleURL"
              class="is-underline"
              target="_blank">
              Download a Sample Template
            </a>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button :disabled="importActive" @click="startImport()" class="button is-primary">
          Import
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/layout/modals/Modal';
import api from '@/http-playmetrics';
import { isEmpty, concat } from 'lodash';
import useToast from 'jseminor/composables/toast';
import { SYSTEM_CROSSBAR } from '@/models/FinancialTracker';

const { success: successToast, error: errorToast } = useToast();

export default {
  components: {
    Modal,
  },
  data() {
    return {
      file: null,
      validating: false,
      validated: false,
      importing: false,
      errors: null,
      actions: [],
      messages: [],
      allURL: '',
      sampleURL: '',
      system: 'PlayMetrics',
    };
  },
  computed: {
    systemURL() {
      if (this.system === SYSTEM_CROSSBAR) {
        return 'cst/crossbar';
      }
      return 'cst/club_internals';
    },
    importActive() {
      return !this.validated || this.importing;
    },
    hasMessages() {
      if (this.messages) {
        return this.messages.length > 0;
      }
      return false;
    },
    hasErrors() {
      return this.errors && this.errors.length > 0;
    },
  },
  methods: {
    clear() {
      this.file = null;
      this.messages = [];
      this.actions = [];
      this.errors = null;
    },
    showModal(system) {
      this.system = system;
      this.$refs.modal.showModal();
      api().serverURL(`${this.systemURL}/export.csv`).then((url) => {
        this.sampleURL = `${url}&sample=true}`;
      });
    },
    dismissModal() {
      this.$emit('dismissModal');
      this.$refs.modal.closeModal();
    },
    createFormForSubmit() {
      const formData = new FormData();
      formData.append('file', this.file);
      return formData;
    },
    startImport() {
      this.importing = true;
      const formData = this.createFormForSubmit();
      const config = { headers: { 'content-type': 'multipart/form-data' } };
      api().post(`${this.systemURL}/import`, formData, config).then((res) => {
        const updates = res.data;
        const message = `Club Financials Imported - ${updates.num_updated} updated / ${updates.num_not_changed} not changed`;
        successToast(message);
        if (this.system === SYSTEM_CROSSBAR) {
          this.$emit('refreshCrossbar');
        } else {
          this.$emit('refreshPlayMetrics');
        }
        this.dismissModal();
      }).finally(() => {
        this.clear();
        this.importing = false;
      })
        .catch((err) => {
          errorToast(`Could not retrieve Crossbar financial data. (${err})`);
        });
    },
    validateFile(file) {
      this.file = file;
      const formData = this.createFormForSubmit();
      this.validating = true;
      const config = { headers: { 'content-type': 'multipart/form-data' } };
      api().post(`${this.systemURL}/validate`, formData, config).then((res) => {
        const updates = res.data;
        if (updates.num_created > 0) this.actions.push(`${updates.num_created} clubs to create`);
        if (updates.num_updated > 0) this.actions.push(`${updates.num_updated} clubs to update`);
        if (updates.num_restored > 0) this.actions.push(`${updates.num_restored} clubs to restore`);
        if (updates.num_not_changed > 0) this.actions.push(`${updates.num_not_changed} clubs to not change`);
        if (updates.num_skipped > 0) this.actions.push(`${updates.num_skipped} clubs skipped`);
        if (!isEmpty(updates.messages)) this.messages = concat(this.messages, updates.messages);
        this.validated = true;
        this.validating = false;
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.length) {
          this.errors = error.response.data;
          if (this.errors.length >= 10) {
            this.messages = [];
            this.messages = concat(this.messages, 'The import stopped due to processing 10 errors.');
          }
        } else {
          this.errors = [api().errorToMessage(error)];
        }
        this.validating = false;
      });
    },
    removeFile() {
      this.errors = null;
      this.actions = [];
      this.messages = [];
      this.file = null;
      this.$emit('file', false);
    },
  },
};
</script>

<style lang="sass" scoped>
.import-modal-body
  margin-bottom: 4rem !important
  color: $black
  .upload-draggable
    width: 100%
    height: 100%
.remove-button
  color: $grey
  border: none
  font-size: 1.1rem
  &:hover
    background: $grey-lighter
    color: $grey-darker
.upload-content
  font-style: italic
  color: $grey
  display: flex
  align-items: center
  justify-content: center
  &:hover
    color: $primary
.file-select
  color: $blue-dodger
.progress-notification
  display: flex
  align-items: center
  color: $black
</style>

<template>
  <div v-if="loading">
    <spinner />
  </div>
  <div id="financial-tracking-table" v-else>
    <PageNavigation
      page-title="Financial Tracking"
      :tabs="systemTabs"
      :active-tab="activeSystemTab"
      @tabClicked="systemTabClicked($event)"
      :dropdownCtas="dropdownCtas"
      @ctaClicked="cta($event)"
    />
    <SpecificClubExportModal ref="export_modal"/>
    <SpecificClubImportModal
      @refreshPlayMetrics="fetchInternalClubs"
      @refreshCrossbar="fetchCBFinancialKpis"
      ref="import_modal"
    />
    <SetSystemModal @new_values="newSystemValues($event)" @refresh="refreshAllKpis" ref="system_modal"/>
    <ExportModal
      ref="export_crossbar_club_values"
      server-url="cst/crossbar"
      server-endpoint="export.csv"
      type="crossbar-organizations"
      :custom-title="`Export Crossbar Club Values`"
      :custom-message="`Generate a .csv file to edit your Crossbar club values in bulk.`"
    />
    <CrossbarTotalsExportModal
      ref="export_crossbar_totals"
    />
    <TablePaginated
      name="Financial Tracking"
      ref="financialTable"
      :data="activeData"
      searchable
      @queryUpdated="searchQuery = $event"
      :filters-active="hasFiltersActive"
      legend
      scrollable>
      <template slot="filter">
        <FTLegend/>
      </template>
      <template #header-left>
        <div>
          <b-tabs
            v-if="kpiTabs.length > 1"
            v-model="activeKPITabIndex"
            type="is-toggle"
            class="b-tabs-no-content"
            @input="storeTabSelection($event)"
          >
            <b-tab-item
              v-for="t in kpiTabs"
              :key="t.tab"
              :label="t.name"
            />
          </b-tabs>
        </div>
      </template>
      <template slot-scope="props">
        <b-table-column
          label="ID"
          field="id"
          sortable>
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          label="Club Name"
          field="name"
          sticky
          sortable>
          <div class="sticky-column">
            <a class="club-name" @click="viewClub(props.row.id)">
              {{ props.row.name }}
            </a>
<!--            <a class="button is-text ml-4"  @click="editClubValues(props.row)">-->
<!--              <fa-icon :icon="['fas', 'pencil']" />-->
<!--            </a>-->
          </div>
        </b-table-column>
        <b-table-column
          label="Last Reviewed"
          field="last_reviewed_at"
          :custom-sort="sortByGenerator('last_reviewed_at')"
          sortable>
          {{ props.row.last_reviewed_at ? (formatDate(props.row.last_reviewed_at, 'MMM D, YYYY')) : "-" }}
        </b-table-column>
        <b-table-column
          label="RR"
          field="risk_rating"
          :custom-sort="sortByGenerator('risk_rating')"
          sortable>
          {{ props.row.risk_rating }}
        </b-table-column>
        <b-table-column
          label="Exp APV"
          numeric
          field="expected_apv"
          :custom-sort="sortByGenerator('expected_apv')"
          sortable>
          <div class="label-col-width">
            {{ props.row.expected_apv | currency }}
          </div>
        </b-table-column>
        <b-table-column
          label="LTM PV"
          numeric
          field="actual_apv"
          :custom-sort="sortByGenerator('actual_apv')"
          sortable>
          <div class="label-col-width">
            {{ props.row.actual_apv | currency }}
          </div>
        </b-table-column>
        <b-table-column
          :visible="activeKPITab === 'Payments'"
          numeric
          label="EPC"
          field="expected_prepayment_coefficient"
          :custom-sort="sortByGenerator('expected_prepayment_coefficient')"
          sortable>
          {{ props.row.expected_prepayment_coefficient ? `${props.row.expected_prepayment_coefficient / 100}%` : '-' }}
        </b-table-column>
        <b-table-column
          :visible="activeKPITab === 'Payments'"
          numeric
          label="APC"
          field="actual_prepayment_coefficient"
          sortable>
          {{ props.row.actual_prepayment_coefficient ?  `${props.row.actual_prepayment_coefficient}%` : '-' }}
        </b-table-column>
        <b-table-column
          :visible="activeKPITab === 'Payments'"
          numeric
          label="Ann PV%"
          field="annual_processing_volume_percentage"
          :cell-class="getCellClass(props.row.annual_processing_volume_percentage, activeKPITab, true)"
          :custom-sort="sortByGenerator('annual_processing_volume_percentage')"
          sortable>
          <div class="label-col-width">
            {{ props.row.annual_processing_volume_percentage !== '' ? `${props.row.annual_processing_volume_percentage}%` : '-' }}
          </div>
        </b-table-column>
        <b-table-column
          :visible="activeKPITab === 'Refunds'"
          numeric
          label="Ann RV%"
          field="annual_refund_volume_percentage"
          :cell-class="getCellClass(props.row.annual_refund_volume_percentage, activeKPITab, true)"
          sortable>
          <div class="label-col-width">
            {{ props.row.annual_refund_volume_percentage ? `${props.row.annual_refund_volume_percentage}%` : '-' }}
          </div>
        </b-table-column>
        <b-table-column
          :visible="activeKPITab === 'Chargebacks'"
          numeric
          label="Ann CBV%"
          field="annual_chargeback_volume_percentage"
          :cell-class="getCellClass(props.row.annual_chargeback_volume_percentage, activeKPITab, true)"
          sortable>
          <div class="label-col-width-45">
            {{ props.row.annual_chargeback_volume_percentage ? `${props.row.annual_chargeback_volume_percentage}%` : '-' }}
          </div>
        </b-table-column>
        <b-table-column
          v-for="(month, index) in props.row.kpisByMonth"
          :key="index"
          :label="month.date.format('MMM YY')"
          numeric
          :field="`${month.date.format('MMM YY')}`"
          :cell-class="`${getCellClass(props.row.kpisByMonth[index].value, activeKPITab)}
            ${getMPLCellClass(props.row.kpisByMonth[index].value, props.row.monthly_processing_limit, activeKPITab)}`"
          :custom-sort="sortByGenerator(`kpisByMonth[${index}].value`)"
          sortable>
          <div class="label-col-width">{{ formatPercentage(props.row.kpisByMonth[index].value) }}</div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <FinancialFilter
      :filters-active="hasFiltersActive"
      :activeTab="activeKPITab"
      @risk_rating="setRiskRating=$event"/>
  </div>
</template>
<script>

import { isEmpty, get, groupBy, reduce } from 'lodash';
import moment from 'moment';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import toPopulateString from 'jseminor/utils/populate';
import ExportModal from '@/components/common/export-modal/ExportModal';
import useToast from 'jseminor/composables/toast';
import { KPI, SYSTEM_CROSSBAR, SYSTEM_PLAYMETRICS } from '@/models/FinancialTracker';
import CrossbarTotalsExportModal from '@/components/financial_tracking/CrossbarTotalsExportModal';
import FTLegend from '@/components/financial_tracking/FTLegend';
import SpecificClubImportModal from '@/components/financial_tracking/SpecificClubImportModal';
import SpecificClubExportModal from '@/components/financial_tracking/SpecificClubExportModal';
import SetSystemModal from '@/components/financial_tracking/SetSystemModal';
import FinancialFilter from '@/components/financial_tracking/FinancialFilter';
import TablePaginated from '@/components/layout/TablePaginated';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';

const { error: errorToast } = useToast();

export default {
  components: {
    CrossbarTotalsExportModal,
    PageNavigation,
    SpecificClubImportModal,
    SpecificClubExportModal,
    ExportModal,
    TablePaginated,
    Spinner,
    SetSystemModal,
    FinancialFilter,
    FTLegend,
    // BaseTabGroup,
  },
  data() {
    return {
      merchantAccountsByClubId: [],
      activeSystemTab: SYSTEM_PLAYMETRICS,
      activeKPITabIndex: 0,
      loading: false,
      clubs: [],
      crossbarOrgs: [],
      clubInternals: [],
      values: {
        annual_payment_concern_percentage: 125,
        annual_payment_risk_percentage: 150,
        monthly_payment_concern_percentage: 50,
        monthly_payment_risk_percentage: 75,

        annual_refund_concern_percentage: 5,
        annual_refund_risk_percentage: 10,
        monthly_refund_concern_percentage: 1,
        monthly_refund_risk_percentage: 3,

        annual_chargeback_concern_percentage: 0.5,
        annual_chargeback_risk_percentage: 1,
        monthly_chargeback_concern_percentage: 0.2,
        monthly_chargeback_risk_percentage: 0.5,
      },
      searchQuery: '',
    };
  },
  computed: {
    clubInternalsByID() {
      return this.clubInternals.reduce((acc, club) => ({ ...acc, [club.id]: club }), {});
    },
    pmTableData() {
      return this.clubs.filter(club => this.clubInternalsByID[club.id]).map((club => ({ ...club,
        last_reviewed_at: this.getLastReviewedAt(this.clubInternalsByID[club.id]?.notes),
        risk_rating: this.clubInternalsByID[club.id]?.risk_rating || 'N/A',
        expected_apv: this.clubInternalsByID[club.id]?.expected_apv || '-',
        monthly_processing_limit: this.clubInternalsByID[club.id]?.monthly_processing_limit || 0,
        actual_apv: this.getAnnualProcessingVolume(club.groupedKpis[KPI.MONTHLY_PAYMENT_AMOUNT]) || '-',
        expected_prepayment_coefficient: this.clubInternalsByID[club.id]?.expected_prepayment_coefficient || 0,
        actual_prepayment_coefficient: this.getActualPrepaymentCoefficient(club.groupedKpis[KPI.MONTHLY_PAYMENT_AMOUNT]),
        annual_processing_volume_percentage: this.getVolumePercentage(this.clubInternalsByID[club.id]?.expected_apv,
          club.groupedKpis[KPI.MONTHLY_PAYMENT_AMOUNT]), // eslint-disable-next-line max-len
        annual_refund_volume_percentage: this.getVolumePercentage(this.clubInternalsByID[club.id]?.expected_apv,
          club.groupedKpis[KPI.MONTHLY_REFUND_AMOUNT]),
        annual_chargeback_volume_percentage: this.getVolumePercentage(this.clubInternalsByID[club.id]?.expected_apv,
          club.groupedKpis[KPI.MONTHLY_DISPUTE_AMOUNT]),
        primary_vendor: this.bestFitMerchant(this.merchantAccountsByClubId[club.id] || [], false)?.vendor ?? '-',
        kpisByMonth: this.getKpisByMonth(this.clubInternalsByID[club.id]?.expected_apv, club.groupedKpis[this.kpisByTab]),
      })));
    },
    cbTableData() {
      return this.crossbarOrgs.map((cbo => ({ ...cbo,
        last_reviewed_at: this.getLastReviewedAt(cbo?.notes),
        risk_rating: cbo?.risk_rating || 'N/A',
        expected_apv: cbo?.expected_apv || '-',
        monthly_processing_limit: cbo?.monthly_processing_limit || 0,
        actual_apv: this.getAnnualProcessingVolume(cbo.groupedKpis[KPI.MONTHLY_PAYMENT_AMOUNT]) || '-',
        expected_prepayment_coefficient: cbo?.expected_prepayment_coefficient || 0,
        actual_prepayment_coefficient: this.getActualPrepaymentCoefficient(cbo.groupedKpis[KPI.MONTHLY_PAYMENT_AMOUNT]),
        annual_processing_volume_percentage: this.getVolumePercentage(cbo?.expected_apv,
          cbo.groupedKpis[KPI.MONTHLY_PAYMENT_AMOUNT]),
        annual_refund_volume_percentage: this.getVolumePercentage(cbo?.expected_apv,
          cbo.groupedKpis[KPI.MONTHLY_REFUND_AMOUNT]),
        annual_chargeback_volume_percentage: this.getVolumePercentage(cbo?.expected_apv,
          cbo.groupedKpis[KPI.MONTHLY_DISPUTE_AMOUNT]),
        primary_vendor: '-',
        kpisByMonth: this.getKpisByMonth(cbo?.expected_apv, cbo.groupedKpis[this.kpisByTab]),
      })));
    },
    filteredClubs() {
      return this.pmTableData.filter(club => this.matchesFinancialFilter(club) &&
      this.matchesName(club, this.searchQuery));
    },
    filteredCrossbarOrgs() {
      return this.cbTableData.filter(cbo => this.matchesFinancialFilter(cbo) &&
        this.matchesName(cbo, this.searchQuery));
    },
    isShowingPlayMetrics() {
      return this.activeSystemTab === SYSTEM_PLAYMETRICS;
    },
    isShowingCrossbar() {
      return this.activeSystemTab === SYSTEM_CROSSBAR;
    },
    activeKPITab() {
      return this.kpiTabs[this.activeKPITabIndex].name;
    },
    activeData() {
      if (this.isShowingPlayMetrics) {
        return this.filteredClubs;
      }
      if (this.isShowingCrossbar) {
        return this.filteredCrossbarOrgs;
      }
      return [];
    },
    kpiTabs() {
      return [
        { name: 'Payments' },
        { name: 'Refunds' },
        { name: 'Chargebacks' },
      ];
    },
    ctas() {
      const ret = [
        this.EXPORT_PM_CLUBS,
        this.IMPORT_PM_CLUBS,
        { divider: true, dropdown_item: true },
        this.EXPORT_CB_CLUBS,
        this.IMPORT_CB_CLUBS,
        { divider: true, dropdown_item: true },
        this.EXPORT_CB_TOTALS,
        this.ADD_SYSTEM_VALUES,
      ];
      return ret;
    },
    systemTabs() {
      return [
        { name: SYSTEM_PLAYMETRICS, ctas: this.ctas },
        { name: SYSTEM_CROSSBAR, ctas: this.ctas },
      ];
    },
    dropdownCtas() {
      const ret = [];
      ret.push(this.EXPORT_CLUBS);
      return ret;
    },
    EXPORT_PM_CLUBS() {
      return { text: 'Export PlayMetrics Club Values', icon: 'sign-in', icon_transform: 'rotate-90 grow-3', dropdown_item: true };
    },
    IMPORT_PM_CLUBS() {
      return { text: 'Import PlayMetrics Club Values', icon: 'file-import', icon_transform: 'grow-3', dropdown_item: true };
    },
    EXPORT_CB_CLUBS() {
      return { text: 'Export Crossbar Club Values', icon: 'sign-in', icon_transform: 'rotate-90 grow-3', dropdown_item: true };
    },
    IMPORT_CB_CLUBS() {
      return { text: 'Import Crossbar Club Values', icon: 'file-import', icon_transform: 'grow-3', dropdown_item: true };
    },
    EXPORT_CB_TOTALS() {
      return { text: 'Export Crossbar Totals', icon: 'sign-in', icon_transform: 'rotate-90 grow-3', dropdown_item: true };
    },
    ADD_SYSTEM_VALUES() {
      return 'Set System Values';
    },
    kpisByTab() {
      switch (this.activeKPITab) {
        case 'Refunds':
          return KPI.MONTHLY_REFUND_AMOUNT;
        case 'Chargebacks':
          return KPI.MONTHLY_DISPUTE_AMOUNT;
        default:
          return KPI.MONTHLY_PAYMENT_AMOUNT;
      }
    },
    hasFiltersActive() {
      return !isEmpty(this.activeFilters);
    },
    activeFilters() {
      return this.$store.getters.activeFiltersForContext('financialTracking');
    },
  },
  created() {
    this.activeSystemTab = this.$route.meta.tab || 'PlayMetrics';
    const previousKPITabValue = this.$store.getters.secondaryTab(this.$router.currentRoute.path);
    if (previousKPITabValue) {
      this.activeKPITabIndex = previousKPITabValue;
    } else {
      this.activeKPITabIndex = 0;
    }

    this.loading = true;
    Promise.all([
      this.fetchMerchantAccounts(),
      this.fetchPMFinancialKpis(),
      this.fetchCBFinancialKpis(),
      this.fetchInternalClubs(),
    ]).then(() => {
      this.loading = false;
    });
  },
  methods: {
    systemTabClicked(tab) {
      if (tab !== this.activeSystemTab) {
        this.activeSystemTab = tab;
        const newName = `Financial Tracking-${tab}`;
        if (this.$route.name !== newName) {
          this.$router.replace({ name: newName });
        }
        const previousKPITabValue = this.$store.getters.secondaryTab(this.$router.currentRoute.path);
        if (previousKPITabValue) {
          this.activeKPITabIndex = previousKPITabValue;
        } else {
          this.activeKPITabIndex = 0;
        }
      }
    },
    storeTabSelection(tabIndex) {
      this.$store.commit('setSecondaryTab', { route: this.$router.currentRoute.path, tab: tabIndex });
    },
    bestFitMerchant(merchants) {
      if (!merchants) return null;
      return merchants.find(merchant => !merchant.flags.includes('Secondary'));
    },
    sortByGenerator(key) {
      let emptyVal;
      let compFunc = (a, b) => a - b;
      switch (key) {
        case 'annual_processing_volume_percentage':
          emptyVal = '';
          break;
        case 'expected_prepayment_coefficient':
          emptyVal = 0;
          break;
        case 'risk_rating':
          emptyVal = 'N/A';
          compFunc = (a, b) => a.localeCompare(b);
          break;
        case 'primary_vendor':
          emptyVal = '-';
          compFunc = (a, b) => a.localeCompare(b);
          break;
        case 'last_reviewed_at':
          emptyVal = '-';
          compFunc = (a, b, isAsc) => {
            if (!a) return 1;
            if (!b) return -1;
            if (moment(a, 'YYYY-MM-DD').isBefore(moment(b, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
            if (moment(a, 'YYYY-MM-DD').isAfter(moment(b, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
            return 0;
          };
          break;
        default:
          emptyVal = '-';
          break;
      }
      return (a, b, isAsc) => {
        const A = get(a, key);
        const B = get(b, key);
        if (A === emptyVal) return 1;
        if (B === emptyVal) return -1;
        return isAsc ? compFunc(A, B) : compFunc(B, A);
      };
    },
    cta(event) {
      switch (event.text ?? event) {
        case 'Import PlayMetrics Club Values':
          this.$refs.import_modal.showModal(SYSTEM_PLAYMETRICS);
          break;
        case 'Export PlayMetrics Club Values':
          this.$refs.export_modal.showModal();
          break;
        case 'Import Crossbar Club Values':
          this.$refs.import_modal.showModal(SYSTEM_CROSSBAR);
          break;
        case 'Export Crossbar Club Values':
          this.$refs.export_crossbar_club_values.showModal();
          break;
        case 'Export Crossbar Totals':
          this.$refs.export_crossbar_totals.showModal();
          break;
        case 'Set System Values':
          this.$refs.system_modal.showModal(this.values);
          break;
        default:
      }
    },
    quit() {
      this.$refs.modal.hide();
    },
    newSystemValues(values) {
      this.values = values;
    },
    editClubValues(club) {
      this.$refs.setClub.showModal(club);
    },
    matchesName(tableData, searchQuery) {
      return tableData?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase() ?? '');
    },
    matchesFinancialFilter(club) {
      if (!this.hasFiltersActive) return true;
      return Object.keys(this.activeFilters).every((key) => {
        if (key === 'advanced' || key === 'advancedR') {
          // TODO: error  Invalid loop. Its body allows only one iteration  no-unreachable-loop
          for (let i = 0; i < this.activeFilters[key].allow.length; i += 1) { // eslint-disable-line no-unreachable-loop
            switch (this.activeFilters[key].allow[i]) {
              case 'expected_apv':
                return club.expected_apv === '-';
              case 'risk_rating':
                return club.risk_rating === 'N/A';
              case 'expected_prepayment_coefficient':
                return club.expected_prepayment_coefficient === 0;
              default:
                return false;
            }
          }
          return false;
        } else {
          return this.activeFilters[key].matches(club);
        }
      });
    },
    getMPLCellClass(mpv, mpl, tab) {
      if ((mpv === '-') || (mpl === 0)) {
        return '';
      }
      if (tab === 'Payments') {
        if (parseFloat(mpv) >= parseFloat(mpl / 100)) {
          return 'border-red';
        }
      }
      return '';
    },
    getCellClass(percentage, tab, isAnnual) {
      if (tab === 'Payments') {
        if (isAnnual) {
          if (percentage >= this.values.annual_payment_risk_percentage) return 'is-danger';
          else if (percentage >= this.values.annual_payment_concern_percentage) return 'is-warning';
        } else if (!isAnnual && percentage >= this.values.monthly_payment_risk_percentage) {
          return 'is-danger';
        } else if (!isAnnual && percentage >= this.values.monthly_payment_concern_percentage) {
          return 'is-warning';
        }
      } else if (tab === 'Refunds') {
        if (isAnnual) {
          if (percentage >= this.values.annual_refund_risk_percentage) return 'is-danger';
          else if (percentage >= this.values.annual_refund_concern_percentage) return 'is-warning';
        } else if (!isAnnual && percentage >= this.values.monthly_refund_risk_percentage) {
          return 'is-danger';
        } else if (!isAnnual && percentage >= this.values.monthly_refund_concern_percentage) {
          return 'is-warning';
        }
      } else if (tab === 'Chargebacks') {
        if (isAnnual) {
          if (percentage >= this.values.annual_chargeback_risk_percentage) return 'is-danger';
          else if (percentage >= this.values.annual_chargeback_concern_percentage) return 'is-warning';
        } else if (!isAnnual && percentage >= this.values.monthly_chargeback_risk_percentage) {
          return 'is-danger';
        } else if (!isAnnual && percentage >= this.values.monthly_chargeback_concern_percentage) {
          return 'is-warning';
        }
      }
      return '';
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
    formatPercentage(num) {
      if (num === '-') return num;
      return `${num}%`;
    },
    calculateMean(arr) {
      return arr.reduce((acc, curr) => acc + curr, 0) / 12;
    },
    calculateStdDev(arr, mean) {
      let num = 0;
      for (let i = 0; i < arr.length; i += 1) {
        num += (arr[i] - mean) ** 2;
      }
      return Math.sqrt(num / 11);
    },
    getLastReviewedAt(notes) {
      if (!isEmpty(notes)) {
        return reduce(notes, (a, b) => (new Date(a.created_at) > new Date(b.created_at) ? a : b)).created_at;
      }
      return null;
    },
    getLastYearKpis(kpis) {
      let lastYearKpis = [];
      const minDate = moment().subtract(13, 'months').endOf('month');
      const maxDate = moment().subtract(1, 'months').endOf('month');
      if (!kpis) {
        for (let i = 0; i < 12; i += 1) lastYearKpis.push(0);
        return lastYearKpis;
      } else {
        lastYearKpis = kpis.filter(kpi => minDate.isSameOrBefore(moment(kpi.captured_on))
          && maxDate.isSameOrAfter(moment(kpi.captured_on))).map(kpi => kpi.value);
        while (lastYearKpis.length < 12) lastYearKpis.push(0);
      }
      return lastYearKpis;
    },
    getActualPrepaymentCoefficient(kpis) {
      const lastYearKpis = this.getLastYearKpis(kpis);
      const mean = this.calculateMean(lastYearKpis);
      if (!mean || !kpis) return '';
      return Math.round(10000 * this.calculateStdDev(lastYearKpis, mean) / mean / Math.sqrt(12)) / 100;
    },
    getAnnualProcessingVolume(kpis) {
      return this.getLastYearKpis(kpis).reduce((acc, curr) => acc + curr, 0);
    },
    getVolumePercentage(expectedApv, kpis) {
      const actualApv = this.getAnnualProcessingVolume(kpis);
      if (!expectedApv && actualApv) return 'inf';
      if (!expectedApv || !kpis) return '';
      return Math.round(10000 * (actualApv / expectedApv)) / 100;
    },
    getKpisByMonth(expectedApv, kpis) {
      const arr = [];
      const kpiDate = moment().subtract(1, 'days');
      if (!expectedApv || !kpis) {
        for (let i = 0; i < 13; i += 1) {
          arr.push({ date: moment(kpiDate), value: '-' });
          kpiDate.month(kpiDate.month() - 1).endOf('month');
        } return arr;
      }
      for (let i = 0; i < 13; i += 1) {
        let kpiValue = kpis.find(kpi => kpiDate.isSame(moment(kpi.captured_on), 'month'))?.value;
        if (!kpiValue) kpiValue = 0;
        arr.push({ date: moment(kpiDate), value: Math.round(10000 * (kpiValue / expectedApv)) / 100 });
        kpiDate.month(kpiDate.month() - 1);
      } return arr;
    },
    viewClub(id) {
      this.$router.push({ name: 'ClubFinancialTracking', params: { club: id, system: this.activeSystemTab, systemValues: this.values } });
    },
    fetchInternalClubs() {
      return api().get('/cst/club_internals?populate=notes').then((res) => {
        this.clubInternals = res.data;
      }).catch((err) => {
        errorToast(`Could not retrieve club internal financial data. (${err})`);
      });
    },
    fetchMerchantAccounts() {
      return api().post('/cst/merchant_accounts/search?populate=user').then((res) => {
        this.merchantAccountsByClubId = groupBy(res.data, 'club_id');
      });
    },
    refreshAllKpis() {
      this.fetchPMFinancialKpis();
      this.fetchCBFinancialKpis();
    },
    fetchPMFinancialKpis() {
      const populate = toPopulateString({
        kpis: {
          merchant_payment_count: true,
          merchant_payment_amount: true,
          merchant_refund_count: true,
          merchant_refund_amount: true,
          merchant_dispute_count: true,
          merchant_dispute_amount: true,
        },
        notes: true,
      });
      return api().post(`/cst/clubs/search?populate=${populate}`)
        .then((ret) => {
          this.clubs = ret.data.filter(club => !club.is_test_club && club.config?.enable_program_administration)
            .map(club => ({ ...club, groupedKpis: groupBy(club.kpis, 'kpi_id') }));
        }).catch((err) => {
          errorToast(`Could not retrieve club financial data. (${err})`);
        });
    },
    fetchCBFinancialKpis() {
      const populate = toPopulateString({
        kpis: true,
        notes: true,
      });
      return api().post(`/cst/crossbar/search?populate=${populate}`)
        .then((ret) => {
          this.crossbarOrgs = ret.data.map(cbo => ({ ...cbo, groupedKpis: groupBy(cbo.kpis, 'kpi_id') }));
        }).catch((err) => {
          errorToast(`Could not retrieve Crossbar financial data. (${err})`);
        });
    },
  },
};
</script>
<style lang="sass" scoped>
@import "~bulma/sass/utilities/mixins"
.club-name
  text-decoration: underline
.exit-button
  padding-left: 22rem
.wrap-class
  display: flex
  justify-content: left
  position: relative
.legend-title
  font-size: 1.3rem
#financial-tracking-table
  .table th
    vertical-align: bottom
  .sticky-column
    display: flex
    justify-content: space-between
  .is-warning
    background-color: rgba($warning-dark, 0.25)
  .is-danger
    background-color: rgba($danger, 0.25)
    color: $grey-darker
  .label-col-width
    min-width: 4rem
  .label_col-width-45
    min-width: 4.5rem
  .border-red
    border-color: $danger
    border-width: 4px
</style>

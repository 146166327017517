<template>
  <div v-if="loading">
    <spinner />
  </div>
  <div v-else class="club-summary">
    <h3 class="club-summary__table-header">Club Overview</h3>
    <div class="columns">
      <div class="column">
        <div class="columns bis-box full-height">
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Club Type</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.type | capitalize }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Customer Since</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.config ? club.config.customer_since : '-' | formatDate() }}
            </div>
          </div>
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Club Sport</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ clubSports(club.sports) | capitalize }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Club Size</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.size || '-' | capitalize }}
            </div>
          </div>
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Onboarding Type</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.config ? club.config.onboarding_type : '-' | capitalize }}
            </div>
          </div>
        </div>
      </div>
      <div class="column" style="margin-left: 0.75rem;">
        <div class="columns bis-box full-height">
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Overall Lead</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.club_onboarding_plans && club.club_onboarding_plans[0].cs_lead_user ?
                club.club_onboarding_plans[0].cs_lead_user.name : '-' }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Overall Secondary</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.club_onboarding_plans && club.club_onboarding_plans[0].cs_secondary_user ?
                club.club_onboarding_plans[0].cs_secondary_user.name : '-' }}
            </div>
          </div>
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Total Activity Time</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ totalActivityTime | formatDuration }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Sales Rep</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.config ? clubSalesContactName(club.config.sales_contact_user_id) : '-' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-label__top">
      <template>
        <span class="stoplight"
          :class="getStatus([tableRow[0].accounts_status, tableRow[0].teams_status, tableRow[0].players_status])">
        </span>
      </template>
      <div class="has-text-weight-bold is-font-mont is-size-4"> Setup </div>
      <ModalPopover :popoverClass="'narrow-popover-info'">
        <template slot="trigger">
          <fa-icon class="icon_blue"
                   :icon="['fas', 'info-circle']"
          />
        </template>
        <template slot="content">
          Dot color based on Accounts, Teams, and Players categories.<br>
          - Red: 2 or 3 categories are red.<br>
          - Yellow: 2 or 3 categories are yellow OR 1 is green, 1 is yellow, 1 is red, OR 2 are green and 1 is yellow.<br>
          - Green: 2 of 3 are green (if 2 are green and 1 is red, color is yellow).
        </template>
      </ModalPopover>
    </div>
    <div class="columns" style="margin: 1rem 0 0 0.5rem">
      <div class="column is-one-third" style="padding-bottom: 0rem;">
        <div class="table-label__sub-header">
          <template>
            <span class="stoplight__sub-header" :class="tableRow[0].accounts_status"></span>
          </template>
          <div class="has-text-weight-bold is-font-mont is-size-6"> Accounts </div>
          <ModalPopover :popoverClass="'narrow-popover-info'">
            <template slot="trigger">
              <fa-icon class="icon_blue"
                       :icon="['fas', 'info-circle']"
              />
            </template>
            <template slot="content">
              Accounts dot color determined by Verified Accounts %.<br>
              - Red: Less than 50%<br>
              - Yellow: 50% to 75%<br>
              - Green: Greater than 75%
            </template>
          </ModalPopover>
        </div>
      </div>
      <div class="column is-one-third" style="padding-bottom: 0rem;">
        <div class="table-label__sub-header">
          <template>
            <span class="stoplight__sub-header" :class="tableRow[0].teams_status"></span>
          </template>
          <div class="has-text-weight-bold is-font-mont is-size-6"> Teams </div>
          <ModalPopover :popoverClass="'narrow-popover-info'">
            <template slot="trigger">
              <fa-icon class="icon_blue"
                       :icon="['fas', 'info-circle']"
              />
            </template>
            <template slot="content">
              Teams dot color determined by Non-Archived Teams %.<br>
              - Red: Less than 50%<br>
              - Yellow: 50% to 75%<br>
              - Green: Greater than 75%
            </template>
          </ModalPopover>
        </div>
      </div>
      <div class="column is-one-third" style="padding-bottom: 0rem;">
        <div class="table-label__sub-header">
          <template>
            <span class="stoplight__sub-header" :class="tableRow[0].players_status"></span>
          </template>
          <div class="has-text-weight-bold is-font-mont is-size-6"> Players </div>
          <ModalPopover :popoverClass="'narrow-popover-info'">
            <template slot="trigger">
              <fa-icon class="icon_blue"
                       :icon="['fas', 'info-circle']"
              />
            </template>
            <template slot="content">
              Players dot color determined by Non-Archived Players %.<br>
              - Red: Less than 50%<br>
              - Yellow: 50% to 75%<br>
              - Green: Greater than 75%
            </template>
          </ModalPopover>
        </div>
      </div>
    </div>
    <div class="columns" style="margin-bottom: 0.5rem; margin-left:0.5rem; align-items: end;">
      <div class="column is-one-third">
        <b-table
          :data="tableRow"
          name="Accounts"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="# of Verified Accounts"
              header-class="is-unselectable"
              centered
              field="accounts">
              {{ props.row.accounts.toLocaleString() }}
            </b-table-column>
            <b-table-column label="# of Contracted Players"
              header-class="is-unselectable"
              centered
              field="players">
              {{ props.row.total_player_cap.toLocaleString() }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              centered
              field="accounts_percentage"
              :class="statusToText(props.row.accounts_status)">
              <template slot="header"> <div style="display: flex; align-items: center;">
                <p>Verified Accounts %</p>
                <ModalPopover :popoverClass="'narrow-popover-info'">
                  <template slot="trigger">
                    <fa-icon class="icon_blue"
                             :icon="['fas', 'info-circle']"
                    />
                  </template>
                  <template slot="content">
                    Verified Accounts %: # of total verified accounts / # of total contracted players.
                  </template>
                </ModalPopover> </div>
              </template>
              {{ props.row.accounts_percentage === '-' ? '-' : `${props.row.accounts_percentage.toLocaleString()}%` }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
      <div class="column is-one-third">
        <b-table
          :data="tableRow"
          name="Teams"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="# of Contracted Players/15"
              header-class="is-unselectable"
              centered
              field="players_over_15">
              {{ Math.floor(props.row.total_player_cap / 15).toLocaleString() }}
            </b-table-column>
            <b-table-column label="# of Non-Archived Teams"
              header-class="is-unselectable"
              centered
              field="teams">
              {{ props.row.teams.toLocaleString() }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              centered
              field="verified_teams"
              :class="statusToText(props.row.teams_status)">
              <template slot="header"> <div style="display: flex; align-items: center;">
                <p>Non-Archived Teams %</p>
                <ModalPopover :popoverClass="'narrow-popover-info'">
                  <template slot="trigger">
                    <fa-icon class="icon_blue"
                             :icon="['fas', 'info-circle']"
                    />
                  </template>
                  <template slot="content">
                    Non-Archived Teams %: (# of contracted players / 15) / # of Non archived teams.
                  </template>
                </ModalPopover> </div>
              </template>
              {{ props.row.teams_percentage === '-' ? '-' : `${props.row.teams_percentage.toLocaleString()}%` }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
      <div class="column is-one-third">
        <b-table
          :data="tableRow"
          name="Players"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="# of Contracted Players"
              header-class="is-unselectable"
              centered
              field="total_player_cap">
              {{ props.row.total_player_cap.toLocaleString() }}
            </b-table-column>
            <b-table-column label="# of Non-Archived Players"
              header-class="is-unselectable"
              centered
              field="players">
              {{ props.row.players.toLocaleString() }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              centered
              field="players_percentage"
              :class="statusToText(props.row.players_status)">
              <template slot="header"> <div style="display: flex; align-items: center;">
                <p>Non-Archived Players %</p>
                <ModalPopover :popoverClass="'narrow-popover-info'">
                  <template slot="trigger">
                    <fa-icon class="icon_blue"
                             :icon="['fas', 'info-circle']"
                    />
                  </template>
                  <template slot="content">
                    Non-Archived Players %: # of total non-archived players / # of total contracted players.
                  </template>
                </ModalPopover> </div>
              </template>
              {{ props.row.players_percentage === '-' ? '-' : `${props.row.players_percentage.toLocaleString()}%` }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
    </div>

    <div class="table-label">
      <template>
        <span class="stoplight"
          :class="getStatus([tableRow[0].programs_status, tableRow[0].transactions_status, tableRow[0].revenue_status])">
        </span>
      </template>
      <div class="has-text-weight-bold is-font-mont is-size-4"> Registrations </div>
      <ModalPopover :popoverClass="'narrow-popover-info'">
        <template slot="trigger">
          <fa-icon class="icon_blue"
                   :icon="['fas', 'info-circle']"
          />
        </template>
        <template slot="content">
          Dot color based on Programs, Transactions, and Revenue categories.<br>
          - Red: 2 or 3 categories are red.<br>
          - Yellow: 2 or 3 categories are yellow OR 1 is green, 1 is yellow, 1 is red, OR 2 are green and 1 is yellow.<br>
          - Green: 2 of 3 are green (if 2 are green and 1 is red, color is yellow).
        </template>
      </ModalPopover>
    </div>
    <div class="columns" style="margin: 1rem 0 0 0.5rem">
      <div class="column is-2" style="padding-bottom: 0rem;">
        <div class="table-label__sub-header">
          <template>
            <span class="stoplight__sub-header" :class="tableRow[0].programs_status"></span>
          </template>
          <div class="has-text-weight-bold is-font-mont is-size-6"> Programs </div>
          <ModalPopover :popoverClass="'narrow-popover-info'">
            <template slot="trigger">
              <fa-icon class="icon_blue"
                       :icon="['fas', 'info-circle']"
              />
            </template>
            <template slot="content">
              Dot color based on Number of Open Programs.<br>
              - Red: No programs<br>
              - Yellow: No open programs<br>
              - Green: Open programs
            </template>
          </ModalPopover>
        </div>
      </div>
      <div class="column" style="padding-bottom: 0rem;">
        <div class="table-label__sub-header">
          <template>
            <span class="stoplight__sub-header" :class="tableRow[0].transactions_status"></span>
          </template>
          <div class="has-text-weight-bold is-font-mont is-size-6"> Transactions </div>
          <ModalPopover :popoverClass="'narrow-popover-info'">
            <template slot="trigger">
              <fa-icon class="icon_blue"
                       :icon="['fas', 'info-circle']"
              />
            </template>
            <template slot="content">
              If there is a competitive player count, Transactions dot is
              based on Competitive Players to Transactions Ratio (past 30 days).
              If not, it is based on the Recreational Players to Transactions Ratio
              (past 180 days).<br>
              - Red: No transactions<br>
              - Yellow: Less than 50%<br>
              - Green: Greater or equal to 50%
            </template>
          </ModalPopover>
        </div>
      </div>
      <div class="column is-one-fifth" style="padding-bottom: 0rem;">
        <div class="table-label__sub-header">
          <template>
            <span class="stoplight__sub-header"
              :class="tableRow[0].revenue_status"></span>
          </template>
          <div class="has-text-weight-bold is-font-mont is-size-6"> Revenue </div>
          <ModalPopover :popoverClass="'narrow-popover-info'">
            <template slot="trigger">
              <fa-icon class="icon_blue"
                       :icon="['fas', 'info-circle']"
              />
            </template>
            <template slot="content">
              Dot color based on the club's revenue: Red: No revenue, Green: Club has revenue.
            </template>
          </ModalPopover>
        </div>
      </div>
    </div>
    <div class="columns" style="align-items: end; margin-bottom: 0.5rem; margin-left: 0.5rem">
      <div class="column is-2">
        <b-table
          :data="tableRow"
          name="Programs"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="Number of Open Programs"
              header-class="is-unselectable"
              centered
              field="programs">
              {{ props.row.open_programs.toLocaleString() }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
      <div class="column">
        <b-table
          :data="tableRow"
          name="Transactions"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="# of Competitive Players"
              header-class="is-unselectable"
              centered
              field="comp_player_cap">
              {{ props.row.comp_player_cap.toLocaleString() }}
            </b-table-column>
            <b-table-column label="# of Recreational Players"
              header-class="is-unselectable"
              centered
              field="rec_player_cap">
              {{ props.row.rec_player_cap.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Competitive Players to Transactions Ratio (past 30 days)"
              header-class="is-unselectable"
              centered
              field="transactions_per_comp_player"
              :class="percentageColor(props.row.transactions_per_comp_player)">
              <template v-slot:header="{ column }"> <div style="display: flex; align-items: center;">
                {{ column.label }}
                <ModalPopover :popoverClass="'narrow-popover-info'">
                  <template slot="trigger">
                    <fa-icon class="icon_blue"
                             :icon="['fas', 'info-circle']"
                    />
                  </template>
                  <template slot="content">
                    Competitive Players to Transactions Ratio (past 30 days):
                    Number of transactions in the last 30 days / # of competitive players
                  </template>
                </ModalPopover> </div>
              </template>
              {{ props.row.transactions_per_comp_player === '-' ? '-'
                : `${props.row.transactions_per_comp_player.toLocaleString()}%` }}
            </b-table-column>
            <b-table-column label="Recreational Players to Transactions Ratio (past 180 days)"
              header-class="is-unselectable"
              centered
              field="transactions_per_rec_player"
              :class="percentageColor(props.row.transactions_per_rec_player)">
              <template v-slot:header="{ column }"> <div style="display: flex; align-items: center;">
                {{ column.label }}
                <ModalPopover :popoverClass="'narrow-popover-info'">
                  <template slot="trigger">
                    <fa-icon class="icon_blue"
                             :icon="['fas', 'info-circle']"
                    />
                  </template>
                  <template slot="content">
                    Recreational Players to Transactions Ratio (past 180 days):
                    Number of transactions in the last 180 days / # of recreational players
                  </template>
                </ModalPopover> </div>
              </template>
              {{ props.row.transactions_per_rec_player === '-' ? '-'
                : `${props.row.transactions_per_rec_player.toLocaleString()}%` }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
      <div class="column is-one-fifth">
        <b-table
          :data="tableRow"
          name="Revenue"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="Revenue Past 30 Days"
              header-class="is-unselectable"
              centered
              field="revenue_last_30_days">
              {{ props.row.revenue_last_30_days | currency }}
            </b-table-column>
            <b-table-column label="Revenue Past 180 Days"
              header-class="is-unselectable"
              centered
              field="revenue_last_180_days">
              {{ props.row.revenue_last_180_days | currency }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
    </div>

    <div class="table-label">
      <template>
        <span class="stoplight" :class="getStatus([tableRow[0].events_status, tableRow[0].forms_status])"></span>
      </template>
      <div class="has-text-weight-bold is-font-mont is-size-4"> Operations </div>
      <ModalPopover :popoverClass="'narrow-popover-info'">
        <template slot="trigger">
          <fa-icon class="icon_blue"
                   :icon="['fas', 'info-circle']"
          />
        </template>
        <template slot="content">
          Dot color based on Forms and Events categories.<br>
          - Red: Both categories are red OR one is red and one is yellow.<br>
          - Yellow: Both categories are yellow OR one is red and one is green OR one is yellow and one is green.<br>
          - Green: Both categories are green.
        </template>
      </ModalPopover>
    </div>
    <div class="columns" style="margin: 1rem 0 0 0.5rem">
      <div class="column is-two-fifths" style="padding-bottom: 0rem;">
        <div class="table-label__sub-header">
          <template>
            <span class="stoplight__sub-header" :class="tableRow[0].events_status"></span>
          </template>
          <div class="has-text-weight-bold is-font-mont is-size-6"> Events </div>
          <ModalPopover :popoverClass="'narrow-popover-info'">
            <template slot="trigger">
              <fa-icon class="icon_blue"
                       :icon="['fas', 'info-circle']"
              />
            </template>
            <template slot="content">
              Dot color based on the Events Usage Rate (next 30 days).<br>
               - Red: No Events<br>
               - Yellow: Less than 50%<br>
               - Green: Greater or equal to 50%
            </template>
          </ModalPopover>
        </div>
      </div>
      <div class="column is-three-fifths" style="padding-bottom: 0rem;">
        <div class="table-label__sub-header">
          <template>
            <span class="stoplight__sub-header" :class="tableRow[0].forms_status"></span>
          </template>
          <div class="has-text-weight-bold is-font-mont is-size-6"> Forms </div>
          <ModalPopover :popoverClass="'narrow-popover-info'">
            <template slot="trigger">
              <fa-icon class="icon_blue"
                       :icon="['fas', 'info-circle']"
              />
            </template>
            <template slot="content">
              Dot color based on the Forms Usage Rate (past 30 days).<br>
               - Red: No Forms<br>
               - Yellow: Less than 50%<br>
               - Green: Greater or equal to 50%"
            </template>
          </ModalPopover>
        </div>
      </div>
    </div>
    <div class="columns" style=" align-items: end; margin-bottom: 0.5rem; margin-left: 0.5rem">
      <div class="column is-two-fifths">
        <b-table
          :data="tableRow"
          name="Events"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="# of Events (next 30 days)"
              header-class="is-unselectable"
              centered
              field="events">
              {{ props.row.events_next_month.toLocaleString() }}
            </b-table-column>
            <b-table-column label="# of Contracted Players/15"
              header-class="is-unselectable"
              centered
              field="players_over_15">
              {{ Math.floor(props.row.total_player_cap / 15).toLocaleString() }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              centered
              field="events_usage_rate"
              :class="statusToText(props.row.events_status)">
              <template slot="header"> <div style="display: flex; align-items: center;">
                <p>Events Usage Rate</p>
                <ModalPopover :popoverClass="'narrow-popover-info'">
                  <template slot="trigger">
                    <fa-icon class="icon_blue"
                             :icon="['fas', 'info-circle']"
                    />
                  </template>
                  <template slot="content">
                    Events Usage Rate: (# of total contracted players / 15) / # of total Events in the next 30 days
                  </template>
                </ModalPopover> </div>
              </template>
              {{ props.row.events_usage_rate === '-' ? '-' : `${props.row.events_usage_rate.toLocaleString()}%` }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
      <div class="column is-half">
        <b-table
          :data="tableRow"
          name="Forms"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="Forms Created (past 30 days)"
              header-class="is-unselectable"
              centered
              field="forms">
              {{ props.row.forms_last_month.toLocaleString() }}
            </b-table-column>
            <b-table-column label="# of Contracted Players/15"
              header-class="is-unselectable"
              centered
              field="players_over_15">
              {{ Math.floor(props.row.total_player_cap / 15).toLocaleString() }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              centered
              field="forms_usage_rate"
              :class="statusToText(props.row.forms_status)">
              <template slot="header"> <div style="display: flex; align-items: center;">
                <p>Forms Usage Rate (past 30 days)</p>
                <ModalPopover :popoverClass="'narrow-popover-info'">
                  <template slot="trigger">
                    <fa-icon class="icon_blue"
                             :icon="['fas', 'info-circle']"
                    />
                  </template>
                  <template slot="content">
                    Forms Usage Rate (past 30 days): # of forms created via form requests in the last 30 days
                     / (# of total contracted players / 15).
                  </template>
                </ModalPopover> </div>
              </template>
              {{ props.row.forms_usage_rate === '-' ? '-' : `${props.row.forms_usage_rate.toLocaleString()}%` }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
    </div>

    <div class="table-label">
      <template>
        <span class="stoplight" :class="tableRow[0].messages_status"></span>
      </template>
      <div class="has-text-weight-bold is-font-mont is-size-4"> Communications </div>
      <ModalPopover :popoverClass="'narrow-popover-info'">
        <template slot="trigger">
          <fa-icon class="icon_blue"
                   :icon="['fas', 'info-circle']"
          />
        </template>
        <template slot="content">
          Dot color based on based on Message Usage Rate<br>
           - Red: No Messages<br>
           - Yellow: Less than 50%<br>
           - Green: Greater or equal to 50%
        </template>
      </ModalPopover>
    </div>
    <div class="columns" style="margin: 1rem 0 0 0.5rem;">
      <div class="column is-three-fifths">
        <div class="table-label__sub-header">
          <template>
            <span class="stoplight__sub-header" :class="tableRow[0].messages_status"></span>
          </template>
          <div class="has-text-weight-bold is-font-mont is-size-6"> Messages </div>
          <ModalPopover :popoverClass="'narrow-popover-info'">
            <template slot="trigger">
              <fa-icon class="icon_blue"
                       :icon="['fas', 'info-circle']"
              />
            </template>
            <template slot="content">
              Dot color based on based on Message Usage Rate<br>
               - Red: No Messages<br>
               - Yellow: Less than 50%<br>
               - Green: Greater or equal to 50%
            </template>
          </ModalPopover>
        </div>
        <b-table
          :data="tableRow"
          name="Messages"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="# of Messages (past 30 days)"
              header-class="is-unselectable"
              centered
              field="messages_last_month">
              {{ props.row.messages_last_month.toLocaleString() }}
            </b-table-column>
            <b-table-column label="# of Contracted Players/15"
              header-class="is-unselectable"
              centered
              field="players_over_15">
              {{ Math.floor(props.row.total_player_cap / 15).toLocaleString() }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              centered
              field="messages_usage_rate"
              :class="statusToText(props.row.messages_status)">
              <template slot="header"> <div style="display: flex; align-items: center;">
                <p>Messages Usage Rate</p>
                <ModalPopover :popoverClass="'narrow-popover-info'">
                  <template slot="trigger">
                    <fa-icon class="icon_blue"
                             :icon="['fas', 'info-circle']"
                    />
                  </template>
                  <template slot="content">
                    Messages Usage Rate: (# of total contracted players / 15) / # of total messages in the last 30 days.
                  </template>
                </ModalPopover> </div>
              </template>
              {{ props.row.messages_usage_rate === '-' ? '-' : `${props.row.messages_usage_rate.toLocaleString()}%` }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
    </div>

    <div class="has-text-weight-bold is-font-mont is-size-4" style="margin: 2rem 0 1.5rem 0;">
      Setup, Registrations, Operations, Communications
    </div>
    <div class="columns" style="margin-bottom: 0; margin-left: 0.5rem">
      <div class="column is-half" style="padding-bottom: 0;">
        <div class="has-text-weight-bold is-font-mont is-size-6"> Setup </div>
      </div>
      <div class="column is-half" style="padding-bottom: 0;">
        <div class="has-text-weight-bold is-font-mont is-size-6"> Registrations </div>
      </div>
    </div>
    <div class="columns" style="align-items: end; margin-left: 0.5rem">
      <div class="column is-half">
        <b-table
          :data="tableRow"
          name="Setup"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="Total Accounts"
              header-class="is-unselectable"
              centered
              field="accounts"
              sortable>
              {{ props.row.accounts.toLocaleString() }}
            </b-table-column>
            <b-table-column label="New Accounts this Week"
              header-class="is-unselectable"
              centered
              field="accounts_delta"
              :class="deltaColor(props.row.accounts_delta)">
              {{ props.row.accounts_delta > 0 ? `+${props.row.accounts_delta.toLocaleString()}`
                : props.row.accounts_delta.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Total Teams"
              header-class="is-unselectable"
              centered
              field="teams">
              {{ props.row.teams.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Teams Delta WoW"
              header-class="is-unselectable"
              centered
              field="teams_delta"
              :class="deltaColor(props.row.teams_delta)">
              {{ props.row.teams_delta > 0 ? `+${props.row.teams_delta.toLocaleString()}`
                : props.row.teams_delta.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Total Players"
              header-class="is-unselectable"
              centered
              field="players">
              {{ props.row.players.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Players Delta WoW"
              header-class="is-unselectable"
              centered
              field="players_delta"
              :class="deltaColor(props.row.players_delta)">
              {{ deltaToString(props.row.players_delta, props.row.players) }}
            </b-table-column>
            <b-table-column label="Total Leagues"
              header-class="is-unselectable"
              centered
              field="leagues">
              {{ props.row.leagues.toLocaleString() }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div><div class="column is-half">
        <b-table
          :data="tableRow"
          name="Registrations"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="Total Open Programs"
              header-class="is-unselectable"
              centered
              field="open_programs">
              {{ props.row.open_programs.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Programs Delta WoW"
              header-class="is-unselectable"
              centered
              field="open_programs_delta"
              :class="deltaColor(props.row.open_programs_delta)">
              {{ props.row.open_programs_delta > 0 ? `+${props.row.open_programs_delta.toLocaleString()}`
                : props.row.open_programs_delta.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Transactions"
              header-class="is-unselectable"
              centered
              field="transactions"
              sortable>
              {{ props.row.transactions.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Revenue"
              header-class="is-unselectable"
              centered
              field="revenue"
              sortable>
              {{ props.row.revenue | currency }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
    </div>

    <div class="columns" style="margin-bottom: 0; margin-left: 0.5rem">
      <div class="column is-half" style="padding-bottom: 0;">
        <div class="has-text-weight-bold is-font-mont is-size-6"> Operations </div>
      </div>
      <div class="column is-half" style="padding-bottom: 0;">
        <div class="has-text-weight-bold is-font-mont is-size-6"> Communications </div>
      </div>
    </div>
    <div class="columns" style="align-items: end; margin-left: 0.5rem">
      <div class="column is-half">
        <b-table
          :data="tableRow"
          name="Operations"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="New Events this Week"
              header-class="is-unselectable"
              centered
              field="new_events">
              {{ props.row.new_events.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Events Delta WoW"
              header-class="is-unselectable"
              centered
              field="events_delta"
              :class="deltaColor(props.row.events_delta)">
              {{ deltaToString(props.row.events_delta, props.row.new_events) }}
            </b-table-column>
            <b-table-column label="Total Open Form Requests"
            header-class="is-unselectable"
            centered
            field="form_requests">
              {{ props.row.form_requests.toLocaleString() }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div> <div class="column is-half">
        <b-table
          :data="tableRow"
          name="Communications"
          class="setup-table"
        >
          <template slot-scope="props">
            <b-table-column label="Messages this Week"
              header-class="is-unselectable"
              centered
              field="messages">
              {{ props.row.messages.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Messages Delta WoW"
              header-class="is-unselectable"
              centered
              field="messages_delta"
              :class="deltaColor(props.row.messages_delta)">
              {{ deltaToString(props.row.messages_delta, props.row.messages) }}
            </b-table-column>
            <b-table-column label="Chats this Week"
              header-class="is-unselectable"
              centered
              field="chats">
              {{ props.row.chats.toLocaleString() }}
            </b-table-column>
            <b-table-column label="Chats Delta WoW"
              header-class="is-unselectable"
              centered
              field="chats_delta"
              :class="deltaColor(props.row.chats_delta)">
              {{ deltaToString(props.row.chats_delta, props.row.chats) }}
            </b-table-column>
          </template>
        </b-table> <div class="table-bottom"/>
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy } from 'lodash';
import spinner from '@/components/common/Spinner';
import ModalPopover from '@/components/common/ModalPopover';

export default {
  components: { spinner, ModalPopover },
  props: {
    club: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    users() {
      return this.$store.getters.users;
    },
    salesUsers() {
      return this.$store.getters.salesUsers;
    },
    clubKpis() {
      return groupBy(this.club.kpis, 'kpi_id');
    },
    tableRow() {
      return [{
        // Setup
        comp_player_cap: this.club.contract?.competitive_player_cap || 0,
        rec_player_cap: this.club.contract?.rec_player_cap || 0,
        total_player_cap: this.club.contract ? this.club.contract.competitive_player_cap + this.club.contract.rec_player_cap : 0,
        accounts: this.getCurrent('10011'),
        accounts_delta: this.getCurrent('10011', true),
        teams: this.getCurrent('10000'),
        teams_delta: this.getCurrent('10000', true),
        players: this.getCurrent('10001'),
        players_delta: this.getCurrent('10001', true),
        leagues: this.getCurrent('10004'),
        // Registrations
        open_programs: this.getCurrent('10005'),
        previous_programs: this.clubKpis['10005'] ? this.clubKpis['10005'].slice(-2)[1].value : '-',
        open_programs_delta: this.getCurrent('10005', true),
        transactions: this.getCurrent('10006'),
        transactions_last_30_days: this.getLastMonths('10006'),
        transactions_last_180_days: this.getLastMonths('10006', true),
        revenue: this.getCurrent('10007'),
        revenue_last_30_days: this.getLastMonths('10007'),
        revenue_last_180_days: this.getLastMonths('10007', true),
        // Operations
        new_events: this.getCurrent('10008'),
        events_delta: this.getCurrent('10008', true),
        events_next_month: this.getNextMonth('10014'),
        form_requests: this.getCurrent('10012'),
        forms_last_month: this.getLastMonths('10012'),
        // Communications
        messages: this.getCurrent('10009'),
        messages_delta: this.getCurrent('10009', true),
        messages_last_month: this.getLastMonths('10009'),
        chats: this.getCurrent('10010'),
        chats_delta: this.getCurrent('10010', true),
      }].map(data => ({ ...data,
        accounts_percentage: this.calculatePercentage(data.accounts, data.total_player_cap),
        teams_percentage: this.calculatePercentage(data.total_player_cap / 15, data.teams),
        players_percentage: this.calculatePercentage(data.players, data.total_player_cap),
        transactions_per_comp_player: this.calculatePercentage(data.transactions_last_30_days, data.comp_player_cap),
        transactions_per_rec_player: this.calculatePercentage(data.transactions_last_180_days, data.rec_player_cap),
        events_usage_rate: this.calculatePercentage(data.events_next_month, data.total_player_cap),
        forms_usage_rate: this.calculatePercentage(data.forms_last_month, data.total_player_cap),
        messages_usage_rate: this.calculatePercentage(data.messages_last_month, data.total_player_cap),
      })).map(data => ({ ...data,
        accounts_status: this.getSetupStatus(data.accounts_percentage),
        teams_status: this.getSetupStatus(data.teams_percentage),
        players_status: this.getSetupStatus(data.players_percentage),
        programs_status: this.getProgramsStatus(data),
        transactions_status: this.getTransactionsStatus(data),
        revenue_status: data.revenue_last_30_days || data.revenue_last_180_days ? 'success' : 'danger',
        events_status: this.getOperationsStatus(data.events_usage_rate),
        forms_status: this.getOperationsStatus(data.forms_usage_rate),
        messages_status: this.getOperationsStatus(data.messages_usage_rate),
      }));
    },
    totalActivityTime() {
      if (this.club.club_onboarding_plans) {
        return this.club.club_onboarding_plans[0].activities?.reduce((acc, activity) => acc + activity.duration, 0) || 0;
      } else return 0;
    },
  },
  methods: {
    // Get most recent kpis for a particular kpi id
    getCurrent(id, delta) {
      if (!this.clubKpis[id]) return 0;
      if (delta) {
        const lastTwoWeeks = this.clubKpis[id].slice(-2);
        return lastTwoWeeks[1].value - lastTwoWeeks[0].value;
      } return this.clubKpis[id].slice(-1)[0].value;
    },
    // Get kpis for the last month or last six months
    getLastMonths(id, getLastSixMonths) {
      if (!this.clubKpis[id]) return 0;
      const dt = new Date();
      if (getLastSixMonths) {
        dt.setMonth(dt.getMonth() - 6);
      } else {
        dt.setMonth(dt.getMonth() - 1);
      } return this.clubKpis[id].filter(kpi => new Date(kpi.captured_on) > dt).reduce((acc, kpi) => acc + kpi.value, 0);
    },
    // Get kpis for the next month
    getNextMonth(id) {
      if (!this.clubKpis[id]) return 0;
      const dt = new Date();
      dt.setMonth(dt.getMonth() + 1);
      return this.clubKpis[id].filter(kpi => new Date(kpi.captured_on) < dt).reduce((acc, kpi) => acc + kpi.value, 0);
    },
    calculatePercentage(num, den) {
      if (num === '-' || !num) return '-';
      return den !== 0 ? Math.floor((num / den) * 100) : '-';
    },
    percentageColor(perc) {
      if (perc === '-') return '';
      else if (!perc) return 'has-text-danger';
      else if (perc < 50) return 'has-text-warning';
      else return 'has-text-success';
    },
    deltaToString(delta, base) {
      if (delta === '-') return '-';
      if (base === 0) {
        if (delta > 0) return `+${delta.toLocaleString()}`;
        else if (delta < 0) return `${delta.toLocaleString()}`;
        else return '0 (0%)';
      }
      if (delta > 0) return `+${delta.toLocaleString()} (${Math.floor((delta / base) * 100)}%)`;
      else if (delta < 0) return `${delta.toLocaleString()} (${Math.floor((delta / base) * 100)}%)`;
      else return '0 (0%)';
    },
    deltaColor(delta) {
      if (delta > 0) return 'has-text-success';
      else if (delta < 0) return 'has-text-danger';
      else return '';
    },
    clubSalesContactName(id) {
      if (!id) return '-';
      return this.salesUsers[id] || '-';
    },
    clubSports(sports) {
      if (sports) {
        return sports[0].sport;
      } return '-';
    },
    statusToText(status) {
      if (status === 'success') return 'has-text-success';
      else if (status === 'warning') return 'has-text-warning-dark';
      else return 'has-text-danger';
    },
    // Get statuses for individual fields
    getSetupStatus(perc) {
      if (perc < 50 || perc === '-') return 'danger';
      else if (perc < 75) return 'warning';
      else return 'success';
    },
    getTransactionsStatus(club) {
      if (club.comp_player_cap && club.transactions_per_comp_player !== '-') {
        if (club.transactions_per_comp_player > 50) return 'success';
        else if (club.transactions_per_comp_player > 0) return 'warning';
        else return 'danger';
      } else if (club.transactions_per_rec_player && club.transactions_per_rec_player !== '-') {
        if (club.transactions_per_rec_player > 50) return 'success';
        else if (club.transactions_per_rec_player > 0) return 'warning';
        else return 'danger';
      } else return 'danger';
    },
    getProgramsStatus(club) {
      if (club.open_programs && club.open_programs !== '-') return 'success';
      else if (club.previous_programs && club.previous_programs !== '-') return 'warning';
      else return 'danger';
    },
    getOperationsStatus(perc) {
      if (!perc || perc === '-') return 'danger';
      else if (perc < 50) return 'warning';
      else return 'success';
    },
    // Get status for entire category
    getStatus(arr) {
      const statusNum = arr.reduce((acc, curr) => {
        let accumulator = acc;
        if (curr === 'success') accumulator += 2;
        else if (curr === 'warning') accumulator += 1;
        return accumulator;
      }, 0);
      const status = statusNum / arr.length;
      if (statusNum === 2 && arr.length === 3) return 'danger';
      else if (status > 1.6) return 'success';
      else if (status > 0.6) return 'warning';
      return 'danger';
    },
  },
};
</script>

<style lang="sass">
.club-summary
  margin-top: 1rem
  &__table-header
    font-family: $family-headers
    font-weight: $weight-semibold
    font-size: $size-4
    margin-bottom: 1.5rem
    margin-top: 2rem
.footer-button
  margin-top: 1rem
  float: right
.table-label
  width: 100%
  display: inline-flex
  align-items: center
  padding-top: 2rem
  &__top
    padding-top: 3rem
    width: 100%
    display: inline-flex
    align-items: center
  &__sub-header
    width: 100%
    display: inline-flex
    align-items: center
.table-bottom
  border-color: #D5DCE3
  border-style: solid none none none
  border-width: 2px
.stoplight
  height: 2.5rem
  width: 2.5rem
  border-radius: 50%
  margin-right: 0.5rem
  white-space: nowrap
  display: inline-block
  &__sub-header
    height: 1.5rem
    width: 1.5rem
    margin-right: 0.5rem
    border-radius: 50%
    white-space: nowrap
    display: inline-block
.icon_blue
  margin-left: .5rem
  color: $blue-brand
.success
  background-color: $success
.warning
  background-color: $warning-dark
.danger
  background-color: $danger
.popover-left
  left: 2
</style>
<style lang="sass">
.setup-table .table th
  vertical-align: bottom
</style>

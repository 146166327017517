<template>
  <div>
    <div v-if="loading">
      <Spinner/>
    </div>
    <div v-else>
      <PageNavigation page-title="Team Accounts Onboarding"/>
      <TablePaginated
        name="Team Accounts Onboarding"
        :data="tableRows"
        searchable
        @queryUpdated="searchQuery = $event"
        :filterable="false">
        <template slot="header-left">
          <b-tabs
            type="is-toggle"
            v-model="activeTab"
            class="b-tabs-no-content">
            <b-tab-item label = "Open"/>
            <b-tab-item label = "All"/>
          </b-tabs>
        </template>
        <template slot-scope="props">
          <b-table-column label="Club Name"
            header-class="is-unselectable"
            field="name"
            sortable>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Club Size"
            header-class="is-unselectable"
            field="size"
            sortable>
            {{ props.row.size | capitalize }}
          </b-table-column>
          <b-table-column label="Sport"
            header-class="is-unselectable"
            field="sport"
            sortable>
            {{ props.row.sport | capitalize}}
          </b-table-column>
          <b-table-column label="KYC Status"
            header-class="is-unselectable"
            field="kyc_status"
            sortable>
            <StatusTag class="underwriting-table__status-section__Badge" :status="props.row.kyc_status" />
          </b-table-column>
          <b-table-column label="Admin Status"
            header-class="is-unselectable"
            field="admin_status"
            sortable>
            {{ props.row.admin_status }}
          </b-table-column>
          <b-table-column label="Treasurer Status"
            header-class="is-unselectable"
            field="treasurer_status"
            sortable>
            {{ props.row.treasurer_status }}
          </b-table-column>
        </template>
        <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
        </section>
        </template>
      </TablePaginated>
    </div>
  </div>
</template>
<script>
import { groupBy, sortBy } from 'lodash';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import TablePaginated from '@/components/layout/TablePaginated';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import StatusTag from '@/components/underwriting/StatusTag';

export default {
  components: {
    TablePaginated,
    PageNavigation,
    Spinner,
    StatusTag,
  },
  data() {
    return {
      searchQuery: '',
      activeTab: 0,
      clubs: [],
      merchantAccounts: [],
      loading: false,
    };
  },
  computed: {
    tableRows() {
      if (!this.activeTab) return this.clubs.filter(club => !(club.team_accounts_completed && club.kyc_status === 'Approved') && this.matchesName(club, this.searchQuery));
      else return this.clubs.filter(club => this.matchesName(club, this.searchQuery));
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    getSecondaryMerchant(id) {
      if (!this.merchantAccounts[id]) return null;
      let secondaryMerchant = null;
      this.merchantAccounts[id].forEach((clubMerchant) => {
        if (clubMerchant.flags.includes('Secondary') && !clubMerchant.flags.includes('Archived')) {
          if (!secondaryMerchant) {
            secondaryMerchant = clubMerchant;
          } else if (clubMerchant.flags.includes('Enabled') && !secondaryMerchant.flags.includes('Enabled')) {
            secondaryMerchant = clubMerchant;
          } else if (secondaryMerchant.flags.includes('Disabled') && !clubMerchant.flags.includes('Disabled')) {
            secondaryMerchant = clubMerchant;
          }
        }
      });
      return secondaryMerchant;
    },
    getKYCStatus(id) {
      const account = this.getSecondaryMerchant(id);
      if (account) {
        if (account.flags.includes('Enabled')) {
          return 'Approved';
        } else if (!account.vendor_account_id) {
          return 'Not Submitted';
        } else {
          return 'In Progress';
        }
      }
      return 'Not Submitted';
    },
    getTeamAccountsStatus(plans) {
      if (!plans) return false;
      let isCompleted = false;
      plans.forEach((plan) => {
        if (plan.team_accounts_status === 'completed') isCompleted = true;
      });
      return isCompleted;
    },
    getStatus(plans, milestone) {
      if (!plans) return 'Not Scheduled';
      for (let i = 0; i < plans.length; i += 1) {
        if (plans[0].activities?.find(act => act.milestone === milestone && act.meeting_date)) return 'Scheduled';
      }
      return 'Not Scheduled';
    },
    matchesName(tableData, searchQuery) {
      return tableData?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase() ?? '');
    },
    fetchClubs() {
      return api().post('/cst/clubs/search?populate=sports,onboarding_plans,onboarding_plans:activities').then((res) => {
        if (res.status === 200) {
          const clubs = res.data.filter(club => !club.is_test_club && this.getSecondaryMerchant(club.id)) || [];
          this.clubs = sortBy(clubs.map(club => ({
            id: club.id,
            name: club.name,
            size: club.size || '-',
            sport: club.sports?.length ? club.sports[0].sport : '-',
            kyc_status: this.getKYCStatus(club.id),
            team_accounts_completed: this.getTeamAccountsStatus(club.club_onboarding_plans?.filter(plan => !plan.archived_at)),
            admin_status: this.getStatus(club.club_onboarding_plans?.filter(plan => !plan.archived_at), 'Admin'),
            treasurer_status: this.getStatus(club.club_onboarding_plans?.filter(plan => !plan.archived_at), 'Treasurer'),
          })), 'name');
        }
      });
    },
    fetchMerchantAccounts() {
      return api().post('cst/merchant_accounts/search').then((res) => {
        if (res.status === 200) {
          const accounts = res.data || [];
          this.merchantAccounts = groupBy(accounts, ma => ma.club_id);
        }
      });
    },
    loadData() {
      this.loading = true;
      this.fetchMerchantAccounts().then(() => this.fetchClubs())
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <FilterTray
      title="Filter Background Checks"
      :filters-active="filtersActive"
      @clear-filters="clearFilters"
    >
      <FilterSection label="Club Size" collapsible>
        <b-field v-for="size in clubSizeOptions" :key="size.value">
          <b-checkbox v-model="filters.size.allow" :native-value="size.value">{{ size.name }}</b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Club Sport" collapsible>
        <b-field v-for="sport in clubSportOptions" :key="sport.value">
          <b-checkbox v-model="filters.sport.allow" :native-value="sport.value">{{ sport.name }}</b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Advanced" collapsible>
        <b-switch @input="$emit('showTestClubs', $event)">
          Show Test Clubs
        </b-switch>
      </FilterSection>
    </FilterTray>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: {
    FilterSection,
    FilterTray,
  },
  props: {
    filtersActive: { type: Boolean, required: true },
  },
  data() {
    return {
      filters: cloneDeep(this.$store.getters.filtersFor('backgroundChecks')),
    };
  },
  computed: {
    clubSizeOptions() {
      return this.$store.getters.clubSizes;
    },
    clubSportOptions() {
      return this.$store.getters.clubSports;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'backgroundChecks' }).then(() => {
        this.filters = cloneDeep(this.$store.getters.filtersFor('backgroundChecks'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'backgroundChecks', filters: cloneDeep(this.filters) });
    },
  },
};
</script>

<style lang="sass" scoped>

</style>

<template>
  <section class="box" :class="{'box-disabled': disabled}">
    <div class="columns is-mobile is-vcentered is-font-mont">
      <div class="column">
        <b-tag
          class="count-tag"
          :class="{'is-empty': numObjs === 0}"
          style="margin-right: 0.25rem"
          rounded>
          {{ numObjs }}
        </b-tag>
        <span class="has-text-weight-semibold">
          {{ title }}
        </span>
      </div>
      <div v-if="canSelect" class="column is-narrow">
        <a @click="$emit('select')">
          <span v-if="headerIconOnly"><fa-icon :icon="['far', 'pencil']" transform="grow-2" /></span>
          <span v-else>
            <span style="margin-right: 0.25rem">
              <fa-icon :icon="['fa', canOnlyAdd ? 'plus' : 'pencil']" transform="grow-2" />
            </span>
            <span v-if="!isMobile" class="has-text-weight-medium">
              {{ canOnlyAdd ? 'Add' : 'Select' }} {{ type | titleize }}
            </span>
          </span>
        </a>
      </div>
    </div>
    <div v-if="message" class="is-italic has-text-grey is-size-7" style="margin-top:-1rem">
      {{ message }}
    </div>
    <b-table
      v-if="showTable && numObjs > 0"
      :data="objs"
      custom-row-key="id"
      class="bis-table">
      <template slot-scope="props">
        <b-table-column
          v-for="field in fields"
          :key="field"
          :label="labelText(field)">
          <span v-if="field.toLowerCase() === 'gender'">{{ props.row[field] | asGenderWord }}</span>
          <span v-else-if="field.toLowerCase() === 'birth_year'">{{ props.row[field] || '-' }}</span>
          <span v-else>{{ props.row[field] }}</span>
        </b-table-column>
        <b-table-column
          custom-key="actions"
          numeric>
          <a
            v-if="canRemoveId(props.row.id)"
            class="button is-text"
            style="margin-top: -0.375rem; margin-bottom: -0.375rem"
            @click="$emit('remove', props.row.id)">
            <span class="icon">
              <fa-icon :icon="['far', 'times']" transform="grow-2" />
            </span>
          </a>
        </b-table-column>
      </template>
    </b-table>
  </section>
</template>

<script>
import { includes } from 'lodash';

export default {
  props: {
    objs: { type: Array, required: true },
    type: { type: String, required: true },
    fields: { type: Array, default: () => [] },
    title: { type: String, required: true },
    message: { type: String, default: '' },
    headerIconOnly: { type: Boolean, default: false },
    canSelect: { type: Boolean, default: true },
    canRemove: { type: Boolean, default: true },
    lockedIds: { type: Array, default: () => [] },
    showTable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    numObjs() {
      return this.objs.length;
    },
    canOnlyAdd() {
      return (this.numObjs === 0) || !this.canRemove;
    },
  },
  methods: {
    labelText(field) {
      if (field === 'region_name') return this.$store.getters.clubDictionary('Region');
      if (field === 'num_teams') return 'Teams';
      return this.$options.filters.titleize(field);
    },
    canRemoveId(id) {
      return this.canRemove && !includes(this.lockedIds, id);
    },
  },
};
</script>

<style lang="sass" scoped>
.box-disabled
  opacity: 0.5
  a
    pointer-events: none
    cursor: default
    &:hover
      color: inherit
</style>

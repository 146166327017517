<template>
  <div>
    <div class="title-style">
      <p class="level-left level-item title__page">Processing Volume</p>
      <a class="button is-primary"
        v-if="searched"
        style="margin-left: 1rem"
        :href="exportAccount"
        target="_blank">
        Export
      </a>
    </div>
    <div class="bis-box header-box">
      <div class="columns">
        <div class="column is-one-third date-range">
          <DateRangeDropdown
            v-model="dateRange"
            :disabled="lifetimeToggle"
            :presets="[
              { name: 'This Week', display: 'This Week', range: thisWeek },
              { name: 'Last Week', display: 'Last Week', range: lastWeek },
              { name: 'Next Week', display: 'Next Week', range: nextWeek },
              { name: 'Last 7 Days', display: 'Last 7 Days', range: last7Days },
              { name: 'Next 7 Days', display: 'Next 7 Days', range: next7Days },
            ]"
          />

        </div>
      <div class="column">
        <b-switch
          class="lifetime-toggle"
          v-model="lifetimeToggle"
          @input="lifetimeTrigger()">
          Lifetime
        </b-switch>
      </div>
    </div>
      <div class="columns">
        <div class="column is-one-third shift-search-down">
          <b-field expanded>
            <b-autocomplete
              class="search-expand"
              :disabled="!this.searchCheck"
              v-model="name"
              :data="filteredNamesArray"
              placeholder="Select a Club..."
              expanded
              clearable
              @select="option => selected = option"
            ></b-autocomplete>
          </b-field>
        </div>
        <div class="column" id="second-row-format">
          <div class="radio-shift-left">
            <b-radio v-model="radio" native-value='Search' >
               <span>Search By Club</span>
            </b-radio>
          </div>
          <div class="checkbox-spacing">
          <b-radio v-model="radio" @click.native="AllClubHandler()" >
            <span>Show All Clubs</span>
          </b-radio>
        </div>
        <div class="checkbox-spacing">
          <b-checkbox v-model="vendors" native-value="WePay">
            WePay
          </b-checkbox>
        </div>
        <div class="checkbox-spacing">
          <b-checkbox v-model="vendors" native-value="Stripe">
            Stripe
          </b-checkbox>
         </div>
         <div class="column">
           <b-button
            class="is-primary search-button-right"
            :disabled="invalid"
            @click="search">
            Search
           </b-button>
         </div>
        </div>
       </div>
     </div>
    <div v-if="searched" class="search-shift" >
      <app-spinner v-if="loading"></app-spinner>
      <div v-else>
        <TablePaginated
          name="processingVolume"
          :filterable="false"
          :searchable="false"
          :data="filteredMerchantClubs"
          narrowed
          mobile-cards
          :row-class="(row, index) => (row.deleted_at != null) ? 'deleted-user' : '' ">
          <template slot-scope="props">
            <b-table-column cell-class="is-unselectable"
              field="club_id"
              label="Club ID"
              sortable>
              {{ props.row.club_id }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              field="club_name"
              label="Club Name"
              sortable>
              {{ props.row.club_name }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              field="vendor"
              label="Vendor"
              sortable>
              {{ props.row.vendor }}<span v-if="!props.row.is_current" title="no longer active">*</span>
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              field="type"
              label="Type"
              sortable>
              {{ props.row.type | capitalize }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              field="payment_amount"
              label="Paid"
              numeric
              sortable>
              {{ props.row.payment_amount | currency }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              field="refund_amount"
              label="Refunded"
              numeric
              sortable>
              {{ props.row.refund_amount | currency }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              field="dispute_amount"
              label="Disputed"
              numeric
              sortable>
              {{ props.row.dispute_amount | currency }}
            </b-table-column>
            <b-table-column header-class="is-unselectable"
              field="projected_amount"
              label="Projected"
              numeric
              sortable>
              {{ props.row.projected_amount | currency }}
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
        </TablePaginated>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import TablePaginated from '@/components/layout/TablePaginated';
import DateRangeDropdown from '@/components/common/DateRangeDropdown';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

const defaultDateRange = {
  startDate: moment().startOf('week').toDate(),
  endDate: moment().endOf('week').toDate(),
};

export default {
  components: {
    'app-spinner': Spinner,
    DateRangeDropdown,
    TablePaginated,
  },

  data() {
    return {
      dateRange: _.cloneDeep(defaultDateRange),
      name: '',
      selected: null,
      loading: true,
      clubs: [],
      merchantClubs: null,
      merchantAccounts: [],
      vendors: ['WePay', 'Stripe'],
      selectClub: null,
      lifetimeToggle: false,
      namMerchantClubs: null,
      club: [],
      urlExport: '',
      searched: false,
      searchQuery: '',
      radio: 'Search',
      searchClicked: false,
      searchQueryNew: this.$store.getters.tableSearchFor(this.name),
    };
  },
  computed: {
    startDate() {
      return this.dateRange.startDate;
    },
    endDate() {
      return this.dateRange.endDate;
    },
    searchCheck() {
      if (this.radio === 'Search') {
        return true;
      } else {
        return false;
      }
    },
    thisWeek() {
      return {
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
      };
    },
    lastWeek() {
      return {
        startDate: moment().subtract(1, 'weeks').startOf('week').toDate(),
        endDate: moment().subtract(1, 'weeks').endOf('week').toDate(),
      };
    },
    nextWeek() {
      return {
        startDate: moment().add(1, 'weeks').startOf('week').toDate(),
        endDate: moment().add(1, 'weeks').endOf('week').toDate(),
      };
    },
    last7Days() {
      return {
        startDate: moment().subtract(7, 'days').toDate(),
        endDate: moment().toDate(),
      };
    },
    next7Days() {
      return {
        startDate: moment().toDate(),
        endDate: moment().add(7, 'days').toDate(),
      };
    },
    filteredMerchantClubs() {
      if (this.searchClicked) {
        this.setSearchClicked();
        if (this.selected !== null && this.radio === 'Search') {
          return this.merchantClubs.map(merchant => ({ ...merchant, type: this.isSecondary(merchant) ? 'secondary' : 'primary' }))
            .filter(merchant => merchant.club_name.toLowerCase().includes(this.selected.toLowerCase()));
        }
        if (!this.radio) {
          return this.merchantClubs.map(merchant => ({ ...merchant, type: this.isSecondary(merchant) ? 'secondary' : 'primary' }))
            .filter(merchant => merchant.club_name.toLowerCase().includes(this.searchQuery.toLowerCase()));
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    namesByClub() {
      if (this.merchantClubs) {
        return [...new Set(this.merchantClubs.map(club => club.club_name))];
      }
      return [];
    },

    filteredNamesArray() {
      return this.namesByClub.filter(option => option.toString().toLowerCase().indexOf(this.name.toLowerCase()) >= 0);
    },
    EXPORT() {
      return { text: 'Export', icon: 'sign-in', icon_transform: 'rotate-90', classes: 'is-outlined' };
    },
    ctas() {
      const ret = [];
      ret.push(this.EXPORT);
      return ret;
    },
    exportAccount() {
      return `${this.urlExport}&data=${JSON.stringify(this.filterData)}`;
    },
    invalid() {
      if (this.radio !== 'Search') {
        return (this.startDate == null) || (this.endDate == null) ||
        this.startDate > this.endDate || _.isEmpty(this.vendors);
      } else {
        return (this.startDate == null) || (this.endDate == null) ||
        this.startDate > this.endDate || this.selected === null;
      }
    },
    filterData() {
      const filters = {};
      if (this.selectClub == null) {
        filters.club_ids = _.map(this.namMerchantClubs, cid => cid.id);
      } else {
        filters.club_ids = [this.selectClub];
      }
      if (this.startDate == null && this.endDate == null) {
        filters.start_date = '2020-01-01';
        filters.end_date = '9999-12-31';
        filters.vendors = this.vendors;
        return filters;
      } else {
        filters.start_date = moment(this.startDate).format('YYYY-MM-DD');
        filters.end_date = moment(this.endDate).format('YYYY-MM-DD');
        filters.vendors = this.vendors;
        return filters;
      }
    },
  },
  watch: {
    searchQueryNew() {
      this.$store.commit('setTableDataFor', { name: this.name, search: this.searchQueryNew });
    },
  },

  methods: {
    setSearchClicked() {
      this.searchClicked = false;
    },
    AllClubHandler() {
      this.selected = null;
      this.name = '';
      this.radio = 'All';
      if (this.radio !== 'Search') {
        this.vendors = ['WePay', 'Stripe'];
      } else {
        this.vendors = [];
      }
    },
    SearchClubHandler() {
      this.radio = 'Search';
    },
    cta($event) {
      if ($event === this.EXPORT) {
        api().serverURL('/cst/merchant_accounts/all-summaries.csv').then(() => {
          window.open(this.urlExport, '_blank');
        });
      }
      return '';
    },
    lifetimeTrigger() {
      if (this.lifetimeToggle) {
        const begDate = new Date();
        const currYear = begDate.getUTCFullYear() + 1;
        begDate.setUTCFullYear(2020);
        begDate.setUTCMonth(0);
        begDate.setDate(1);
        const finalDate = new Date();
        finalDate.setUTCFullYear(currYear);
        finalDate.setUTCMonth(11);
        finalDate.setUTCDate(31);
        this.dateRange.startDate = begDate;
        this.dateRange.endDate = finalDate;
      } else {
        this.dateRange = _.cloneDeep(defaultDateRange);
      }
    },
    newSearch() {
      this.searched = false;
      this.merchantClubs = [];
      this.dateRange = _.cloneDeep(defaultDateRange);
      this.club = [];
      this.lifetimeToggle = false;
      this.selectClub = null;
      this.vendors = ['WePay', 'Stripe'];
      this.loading = true;
    },
    search() {
      this.searched = true;
      this.searchClicked = true;
      this.loading = true;
      api().post(`/cst/merchant_accounts/search?data=${JSON.stringify(this.filterData)}`).then((resp3) => {
        this.merchantClubs = _.map(resp3.data, (maClub2) => {
          const maClubWithName2 = _.clone(maClub2);
          const club2 = _.find(this.clubs, c => c.id === maClub2.club_id);
          if (club2) {
            maClubWithName2.club_name = club2.name;
          }
          return maClubWithName2;
        });
        this.merchantClubs = _.filter(this.merchantClubs, del => del.club_id > 0);
        this.loading = false;
      });
    },
    fetchMerchantAccounts() {
      this.loading = true;
      api().post('/cst/merchant_accounts/search').then((res) => {
        this.merchantAccounts = res.data;
        this.loading = false;
      });
    },
    fetchClubs() {
      this.loading = true;
      api().post('/cst/clubs/search').then((resp1) => {
        this.clubs = _.map(resp1.data, (club) => {
          let name = club.name;
          if (club.is_test_club) {
            name += ' (test club)';
          }
          club.name = name;
          return club;
        });
        api().post('/cst/merchant_accounts/search').then((resp2) => {
          this.merchantClubs = _.map(resp2.data, (maClub) => {
            const maClubWithName = _.clone(maClub);
            const club = _.find(this.clubs, c => c.id === maClub.club_id);
            if (club) {
              maClubWithName.club_name = club.name;
            }
            return maClubWithName;
          });
          this.merchantClubs = _.filter(this.merchantClubs, del => del.club_id > 0);
          this.namMerchantClubs = _.filter(this.clubs, c => !_.isEmpty(_.filter(this.merchantClubs, mac => mac.club_id === c.id)));
          this.namMerchantClubs = _.sortBy(this.namMerchantClubs, c => c.name);
          this.loading = false;
        });
      });
    },
    isSecondary(cm) {
      const merchant = this.merchantAccounts.find(m => m.id === cm.club_merchant_id);
      return merchant?.flags.indexOf('Secondary') >= 0;
    },
  },
  created() {
    this.fetchMerchantAccounts();
    this.fetchClubs();
    api().serverURL('/cst/merchant_accounts/all-summaries.csv').then((url) => {
      this.urlExport = url;
    });
  },
};
</script>

<style lang="sass">
@import "~bulma/sass/utilities/mixins"
.page__breadcrumb
  margin-top: -2rem
  @media screen and (min-width: 1024px)
    margin-top: -3rem
  ul
    flex-wrap: initial
  &.back-button
    position: relative
    z-index: 1
    &.nopadding
      margin-bottom: 0
    a
      padding: 0.25rem 0
      justify-content: flex-start
      display: inline-flex
      font-family: $family-headers
      font-weight: $weight-medium
      font-size: $size-7
.title__page
  line-height: calc(45px + 0.5rem)
.level-left
  flex-grow: 1
  flex-shrink: 1
.level-item
  justify-content: flex-start
.header-box
  border-style: solid
  border-color: #D5DCE3
  border-width: .15rem
  padding: 0
  margin-top: 0
  margin-bottom: 1rem
.search-line
  display: inline-block
.club-selector
  width: 100%
#second-row-format
  display: flex !important
  flex-direction: row
.title-style
  display: flex
  flex-direction: row
  justify-content: space-between
  margin-bottom: 3rem
.date-range
  margin-top: .7rem
  float: left
.lifetime-toggle
  float: left
.shift-button
  margin-left: 2rem
.search-shift
  margin-top: 2rem
.shift-search-down
  float: left
  margin-top: 0.8rem
.radio-shift-left
  display: flex
  flex-direction: row
  margin-left: 1.2rem
.checkbox-spacing
  display: flex
  flex-direction: row
  margin-left: 1rem
.search-button-right
  float: right
.lifetime-toggle
  margin-top: 0.7rem
  margin-left: 1rem
.search-expand
  width: 100%
</style>

<template>
  <div class="columns">
    <div class="column">
      <p style="margin-right: .1rem" class="view-label">
        Timeframe:
      </p>
    </div>
    <div style="margin: auto">
      <ModalPopover
      ref="modal"
      trigger="click"
      placement="bottom-end">
        <template slot="trigger">
          <div class="columns">
            <div class="column is-four-fifths">
              <div class="view-name">
                <a class="item-line">
                  <p class="line-item">{{ optionName }}</p>
                  <fa-icon
                  :icon="['fas', 'caret-down']"
                  class="is-size-16px item-line"
                  transform="right-5"/>
                </a>
              </div>
            </div>
          </div>
        </template>
        <template slot="content">
          <div>
            <p class="header-title">TIMEFRAME</p>
            <b-field>
              <b-radio :key="option.name"
                v-for="option in options"
                v-model="selectedView"
                :native-value="option.value">
                {{ option.name }}
              </b-radio>
            </b-field>
            <p class="header-title">START DATE</p>
            <DatePickerString :type="calendarView"
              :date-formatter="formatDate"
              v-model="calendarDate"
              :editable="!calendarView"/>
            <b-switch style="margin-top: 1rem" v-if="hasToggle" @input="$event => overlayToggle = $event">
              View Year Over Year
            </b-switch>
            <div class="button-cont">
              <div style="flex-grow: 1"></div>
              <b-button class="button-position no-button-hover cancel-button is-text" @click="quit()">Cancel</b-button>
              <b-button class="button-position is-primary" @click="close()">Apply</b-button>
            </div>
          </div>
        </template>
      </ModalPopover>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ModalPopover from '@/components/common/ModalPopover';
import DatePickerString from '@/components/common/form-elements/DatePickerString';

export default {
  components: { ModalPopover, DatePickerString },
  props: {
    value: { type: Object, required: true },
    options: { type: Array, required: true },
    hasToggle: { type: Boolean, required: false, default: false },
    numLastUnits: { type: Number, default: 12 },
  },
  computed: {
    calendarDate: {
      get() {
        return moment(this.selectedDate).toString();
      },
      set(value) {
        this.selectedDate = moment(value, 'YYYY-MM-DD').toDate();
      },
    },
    calendarView() {
      return this.selectedView === 'month' ? 'month' : null;
    },
    selectedView: {
      get() {
        return this.chosenView;
      },
      set(value) {
        this.chosenView = value;
        this.selectedDate = moment().startOf(this.chosenView).subtract(this.numLastUnits, this.chosenView).startOf(this.chosenView)
          .toDate();
      },
    },
    optionName() {
      return this.options.find(opt => opt.value === this.value.view).name;
    },
  },
  created() {
    this.currentView = this.value.view;
    this.selectedDate = this.value.date;
    this.chosenView = this.value.view;
  },
  data() {
    return {
      currentView: null,
      selectedDate: null,
      chosenView: null,
      overlayToggle: false,
    };
  },
  methods: {
    yoyToggle(event) {
      this.$emit('yearOverYearToggle', event);
    },
    formatDate(date) {
      return moment(date).format('ddd, MMM D, YYYY');
    },
    close() {
      this.$emit('input', {
        date: this.selectedDate,
        view: this.selectedView,
      });
      this.$emit('yearOverYearToggle', this.overlayToggle);
      this.quit();
    },
    quit() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style lang="sass" scoped>
.view-label
  font-family: $family-headers
  font-weight: $weight-medium
  font-size: $size-7
  color: $blue-slate
  margin-top: 0.2rem
.item-line
  display: inline
  white-space: nowrap
  .line-item
    display: inline-block
.view-label
  font-family: $family-headers
  font-weight: $weight-medium
  font-size: $size-7
  color: $blue-slate
  margin-top: 0.2rem
.view-name
  font-family: $family-headers
  font-weight: $weight-semibold
  color: $black
  font-size: 1.15rem
.button-cont
  display: flex
  justify-content: flex-end
  margin-top: 0.6rem
.button-position
  margin-left: 0px
  margin-right: 0px
  margin-left: auto
.button.no-button-hover
  &:hover
    background-color: transparent !important
.header-title
  font-weight: $weight-bold
  font-family: $family-montserrat
  font-size: $size-7
  color: $black
</style>

<template>
  <div>
    <div class="charts-container">
      <Spinner v-if="loading"/>
      <template v-else>
        <div class="grid">
          <div class="chart">
            <Highcharts :options="chartOverallLead"/>
          </div>
          <div class="chart">
            <Highcharts :options="chartRegistrationLead"/>
          </div>
          <div class="chart">
            <Highcharts :options="chartOperationsLead"/>
          </div>
          <div class="chart">
            <Highcharts :options="chartOverallSecondary"/>
          </div>
          <div class="chart">
            <Highcharts :options="chartRegistrationSecondary"/>
          </div>
          <div class="chart">
            <Highcharts :options="chartOperationsSecondary"/>
          </div>
          <div class="chart">
            <Highcharts :options="chartOverallTotal"/>
          </div>
          <div class="chart">
            <Highcharts :options="chartRegistrationTotal"/>
          </div>
          <div class="chart">
            <Highcharts :options="chartOperationsTotal"/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';
import _ from 'lodash';
import Highcharts from 'highcharts';
import { genComponent } from 'vue-highcharts';

export default {
  components: { Spinner, Highcharts: genComponent('Highcharts', Highcharts) },
  data() {
    return {
      oPlans: [],
      clubs: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    loading() {
      return !(this.overallLeadSeries && this.overallSecondarySeries && this.overallTotalSeries &&
      this.registrationLeadSeries && this.registrationSecondarySeries && this.registrationTotalSeries &&
      this.operationsLeadSeries && this.operationsSecondarySeries && this.operationsTotalSeries && this.onboardingPlans);
    },
    onboardingPlans() {
      return this.oPlans.filter(plan => !(plan.archived_at || plan.status === 'completed')).map(plan => ({
        club_id: plan.club_id,
        isCustomer: this.clubs.find(club => club.id === plan.club_id)?.type !== 'prospect',
        overall_lead: plan.cs_lead_user_id,
        registration_lead: plan.registration_lead_user_id,
        operations_lead: plan.operations_lead_user_id,
        overall_secondary: plan.cs_secondary_user_id,
        registration_secondary: plan.registration_secondary_user_id,
        operations_secondary: plan.operations_secondary_user_id,
      }));
    },
    csStaffMembers() {
      return this.$store.getters.usersByDepartments(['success']).map(user => ({
        name: user.first_name,
        id: user.id,
      })).sort((a, b) => a.id - b.id);
    },
    baseChartOptions() {
      return {
        stackLabel: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: '#fff',
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
          },
        },
        chart: {
          borderColor: 'grey',
          borderWidth: 1,
          showAxes: true,
          spacingTop: 50,
          type: 'column',
        },
        credits: {
          enabled: false,
        },
        title: {
          y: -15,
          margin: 30,
          style: { color: 'is-black' },
        },
        xAxis: {
          startOnTick: true,
          title: { text: 'CS Staff Member' },
          categories: this.csStaffMembers.map(user => user.name),
        },
        yAxis: {
          min: 0,
          title: { text: 'Club Count' },
        },
        legend: {
          y: 30,
          floating: true,
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
        },
      };
    },
    userData() {
      const counts = {};
      this.csStaffMembers.forEach((u) => {
        counts[u.id] = {
          overall_lead: { customer: 0, prospect: 0 },
          registration_lead: { customer: 0, prospect: 0 },
          operations_lead: { customer: 0, prospect: 0 },
          overall_secondary: { customer: 0, prospect: 0 },
          registration_secondary: { customer: 0, prospect: 0 },
          operations_secondary: { customer: 0, prospect: 0 },
          overall_total: { customer: 0, prospect: 0 },
          registration_total: { customer: 0, prospect: 0 },
          operations_total: { customer: 0, prospect: 0 },
        };
      });
      this.onboardingPlans.forEach((plan) => {
        if (counts[plan.overall_lead]) {
          counts[plan.overall_lead].overall_lead[plan.isCustomer ? 'customer' : 'prospect'] += 1;
          counts[plan.overall_lead].overall_total[plan.isCustomer ? 'customer' : 'prospect'] += 1;
        }
        if (counts[plan.overall_secondary]) {
          counts[plan.overall_secondary].overall_secondary[plan.isCustomer ? 'customer' : 'prospect'] += 1;
          counts[plan.overall_secondary].overall_total[plan.isCustomer ? 'customer' : 'prospect'] += 1;
        }
        if (counts[plan.registration_lead]) {
          counts[plan.registration_lead].registration_lead[plan.isCustomer ? 'customer' : 'prospect'] += 1;
          counts[plan.registration_lead].registration_total[plan.isCustomer ? 'customer' : 'prospect'] += 1;
        }
        if (counts[plan.registration_secondary]) {
          counts[plan.registration_secondary].registration_secondary[plan.isCustomer ? 'customer' : 'prospect'] += 1;
          counts[plan.registration_secondary].registration_total[plan.isCustomer ? 'customer' : 'prospect'] += 1;
        }
        if (counts[plan.operations_lead]) {
          counts[plan.operations_lead].operations_lead[plan.isCustomer ? 'customer' : 'prospect'] += 1;
          counts[plan.operations_lead].operations_total[plan.isCustomer ? 'customer' : 'prospect'] += 1;
        }
        if (counts[plan.operations_secondary]) {
          counts[plan.operations_secondary].operations_secondary[plan.isCustomer ? 'customer' : 'prospect'] += 1;
          counts[plan.operations_secondary].operations_total[plan.isCustomer ? 'customer' : 'prospect'] += 1;
        }
      });
      return counts;
    },
    chartOverallLead() {
      return _.merge({
        title: {
          text: '<strong> Overall Lead </strong>',
        },
        series: this.overallLeadSeries,
      }, this.baseChartOptions);
    },
    chartRegistrationLead() {
      return _.merge({
        title: {
          text: '<strong> Registration Lead </strong>',
        },
        series: this.registrationLeadSeries,
      }, this.baseChartOptions);
    },
    chartOperationsLead() {
      return _.merge({
        title: {
          text: '<strong> Operations Lead </strong>',
        },
        series: this.operationsLeadSeries,
      }, this.baseChartOptions);
    },
    chartOverallSecondary() {
      return _.merge({
        title: {
          text: '<strong> Overall Secondary </strong>',
        },
        series: this.overallSecondarySeries,
      }, this.baseChartOptions);
    },
    chartRegistrationSecondary() {
      return _.merge({
        title: {
          text: '<strong> Registration Secondary </strong>',
        },
        series: this.registrationSecondarySeries,
      }, this.baseChartOptions);
    },
    chartOperationsSecondary() {
      return _.merge({
        title: {
          text: '<strong> Operations Secondary </strong>',
        },
        series: this.operationsSecondarySeries,
      }, this.baseChartOptions);
    },
    chartOverallTotal() {
      return _.merge({
        title: {
          text: '<strong> Total Engagement Overall </strong>',
        },
        series: this.overallTotalSeries,
      }, this.baseChartOptions);
    },
    chartRegistrationTotal() {
      return _.merge({
        title: {
          text: '<strong> Total Engagement Registration </strong>',
        },
        series: this.registrationTotalSeries,
      }, this.baseChartOptions);
    },
    chartOperationsTotal() {
      return _.merge({
        title: {
          text: '<strong> Total Engagement Operations </strong>',
        },
        series: this.operationsTotalSeries,
      }, this.baseChartOptions);
    },
    overallLeadSeries() {
      return [{ name: 'Prospect', data: this.csStaffMembers.map(user => this.userData[user.id].overall_lead.prospect), color: 'red' },
        { name: 'Customer', data: this.csStaffMembers.map(user => this.userData[user.id].overall_lead.customer), color: 'blue' }];
    },
    registrationLeadSeries() {
      return [
        { name: 'Prospect', data: this.csStaffMembers.map(user => this.userData[user.id].registration_lead.prospect), color: 'red' },
        { name: 'Customer', data: this.csStaffMembers.map(user => this.userData[user.id].registration_lead.customer), color: 'blue' }];
    },
    operationsLeadSeries() {
      return [
        { name: 'Prospect', data: this.csStaffMembers.map(user => this.userData[user.id].operations_lead.prospect), color: 'red' },
        { name: 'Customer', data: this.csStaffMembers.map(user => this.userData[user.id].operations_lead.customer), color: 'blue' }];
    },
    overallSecondarySeries() {
      return [
        { name: 'Prospect', data: this.csStaffMembers.map(user => this.userData[user.id].overall_secondary.prospect), color: 'red' },
        { name: 'Customer', data: this.csStaffMembers.map(user => this.userData[user.id].overall_secondary.customer), color: 'blue' }];
    },
    registrationSecondarySeries() {
      return [
        { name: 'Prospect', data: this.csStaffMembers.map(user => this.userData[user.id].registration_secondary.prospect), color: 'red' },
        { name: 'Customer', data: this.csStaffMembers.map(user => this.userData[user.id].registration_secondary.customer), color: 'blue' }];
    },
    operationsSecondarySeries() {
      return [
        { name: 'Prospect', data: this.csStaffMembers.map(user => this.userData[user.id].operations_secondary.prospect), color: 'red' },
        { name: 'Customer', data: this.csStaffMembers.map(user => this.userData[user.id].operations_secondary.customer), color: 'blue' }];
    },
    overallTotalSeries() {
      return [
        { name: 'Prospect', data: this.csStaffMembers.map(user => this.userData[user.id].overall_total.prospect), color: 'red' },
        { name: 'Customer', data: this.csStaffMembers.map(user => this.userData[user.id].overall_total.customer), color: 'blue' }];
    },
    registrationTotalSeries() {
      return [
        { name: 'Prospect', data: this.csStaffMembers.map(user => this.userData[user.id].registration_total.prospect), color: 'red' },
        { name: 'Customer', data: this.csStaffMembers.map(user => this.userData[user.id].registration_total.customer), color: 'blue' }];
    },
    operationsTotalSeries() {
      return [
        { name: 'Prospect', data: this.csStaffMembers.map(user => this.userData[user.id].operations_total.prospect), color: 'red' },
        { name: 'Customer', data: this.csStaffMembers.map(user => this.userData[user.id].operations_total.customer), color: 'blue' }];
    },
  },
  methods: {
    fetchData() {
      this.fetchOnboardingPlans();
      this.fetchClubs();
    },
    fetchClubs() {
      api().post('cst/clubs/search').then((res) => {
        if (res.status === 200) {
          this.clubs = res.data;
        }
      });
    },
    fetchOnboardingPlans() {
      api().post('cst/club_onboarding_plans/search').then((res) => {
        if (res.status === 200) {
          this.oPlans = res.data;
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.charts-container
  padding: 2rem
.chart
  width: 100%
  height: 100%
.grid
  display: grid
  gap: 1rem
  grid-template-columns: repeat(3, 1fr)
</style>

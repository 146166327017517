<template>
  <div>
    <Modal ref="modal" hasCancelButton :title="'Edit Admin Permissions'">
      <template>
        <div>
          <b-field label="Email">
            <b-input v-model="user.email" :disabled="true"/>
          </b-field>
          <b-field label="Permissions"/>
          <div v-for="(perm, idx) in this.cstPermissions"
            :key="idx"
            class="columns">
            <div class="column">
              <b-field>
                <b-checkbox v-model="selectedPermissions" :native-value="perm">
                  {{ getPermissionName(perm) }}
                </b-checkbox>
              </b-field>
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <b-button type="is-primary" @click="save()">
          Update Admin
        </b-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { intersection, union } from 'lodash';
import Modal from '@/components/layout/modals/Modal';
import api from '@/http-playmetrics';

export default {
  components: { Modal },
  data() {
    return {
      cstPermissions: [],
      adminPermissions: [],
      selectedPermissions: [],
      user: {},
    };
  },
  created() {
    api().get('/cst/admin_permissions').then((response) => {
      if (response.status === 200) {
        this.cstPermissions = response.data.filter(perm => perm.startsWith('cst'));
      }
    });
  },
  methods: {
    getPermissionName(value) {
      switch (value) {
        case 'cst_tool': return 'CST Administrator';
        case 'cst_tool.dept_sales': return 'Sales Leadership';
        case 'cst_tool.dept_product': return 'Product';
        case 'cst_tool.dept_success': return 'Customer Success';
        case 'cst_tool.dept_marketing': return 'Marketing';
        case 'cst_tool.dept_operations': return 'Operations';
        default: return value;
      }
    },
    save() {
      const post = union(this.selectedPermissions, this.adminPermissions);
      api().post(`/cst/admin_users/${this.user.id}/permissions`, post).then(() => {
        this.$store.dispatch('setSuccessToast', 'Admin User Saved');
        this.dismissModal({ refreshData: true });
      });
    },
    dismissModal(options = { refeshData: false }) {
      this.$emit('dismiss', options);
      this.$refs.modal.dismissModal();
    },
    show(user) {
      if (this.users.some(u => u.id === user.id)) {
        this.user = user;
        this.selectedPermissions = intersection(this.user.admin_permissions, this.cstPermissions);
        this.adminPermissions = this.user.admin_permissions.filter(perm => perm.startsWith('admin'));
        this.$refs.modal.showModal();
      }
    },
  },
  computed: {
    users() {
      return this.$store.getters.users;
    },
  },
};
</script>
